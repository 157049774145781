/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Users from "./Users";

const componentStyle = css`

`
class GaigUsers extends React.Component{

    componentDidMount(){
        window.scrollTo(0,0)
    }
            
    render(){
        const { accountsContainer } = this.props

        return(
            
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h2>GAIG Users</h2>
                    </div>
                </div>

                <Users accountsContainer={accountsContainer} />
            </div>
        )
    }   
}

export default GaigUsers