/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Modal, Form, Button } from "react-bootstrap";

class ReassignAgencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAgencyId: "",
      filteredAgencies: [],
      validationMessage: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { show, agencyId, agencies } = this.props;
    if (prevProps.show !== show || prevProps.agencyId !== agencyId) {
      const filteredAgencies = agencies?.filter(
        (agency) => agency.id !== agencyId
      );
      this.setState({ filteredAgencies });
    }
  }

  handleAgencyChange = (e) => {
    this.setState({
      selectedAgencyId: e.target.value,
      validationMessage: "",
    });
  };

  handleAssignClick = async () => {
    const { selectedAgencyId } = this.state;
    const { selectedContact, onHide, accountsContainer } = this.props;

    if (selectedAgencyId === "") {
      this.setState({ validationMessage: "Please select an agency." });
    } else {
      const result = await accountsContainer.switchAccountAgency({
        userId: selectedContact.id,
        brokerCompanyId: selectedAgencyId,
      });

      if (!result?.succeeded) {
        console.log("There was an error switching the agency");
        return;
      } else {
        onHide(true);
      }
    }
  };

  render() {
    const { show, onHide } = this.props;
    const { selectedAgencyId, filteredAgencies, validationMessage } =
      this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          onHide(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reassign Agency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUser">
              <Form.Label>Select Agency</Form.Label>
              <Form.Select
                className="form-select-sm"
                value={selectedAgencyId}
                required
                onChange={this.handleAgencyChange}
              >
                <option value="">Select Agency</option>
                {filteredAgencies?.map((agency) => (
                  <option key={agency.id} value={agency.id}>
                    {agency.name}
                  </option>
                ))}
              </Form.Select>
              {validationMessage && (
                <Form.Text className="text-danger">
                  {validationMessage}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-secondary float-right"
            onClick={() => {
              onHide(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary float-right"
            style={{ backgroundColor: "#7694aa", borderColor: "#7694aa" }}
            onClick={this.handleAssignClick}
          >
            Assign
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReassignAgencyModal;
