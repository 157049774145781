/** @jsxImportSource @emotion/react */
import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import { Subscribe } from "unstated"
import history from "../components/History"
import Home from "./Home/"
import Settings from "./Settings"
import Application1 from "./Application/Application1"
import Application2 from "./Application/Application2"
import Application3 from "./Application/Application3"
import Bind from "./Application/Bind"
import ApplicationStatus from "./Application/ApplicationStatus"
import AgenciesAndContacts from "./AgenciesAndContacts/"
import AddAgency from "./AgenciesAndContacts/AddAgency"
import AddContact from "./AgenciesAndContacts/AddContact"
import EditRegistration from "./AgenciesAndContacts/EditRegistration"
import GaigUsers from "./GaigUsers/"
import AddUser from "./GaigUsers/AddUser"
import Endorsements from "./Endorsements/"
import AddEndorsement from "./Endorsements/AddEndorsement"
import AccountsContainer from "../state/AccountsContainer"
import AuthenticationWrapper from "./Authentication/AuthenticationWrapper"
import ApplicationsContainer from "../state/ApplicationsContainer"
import BrokerCompaniesContainer from "../state/BrokerCompaniesContainer"
import RegistrationsContainer from "../state/RegistrationsContainer"
import PolicyNumbersContainer from "../state/PolicyNumbersContainer"
import EndorsementsContainer from "../state/EndorsementsContainer"
import ReportingContainer from "../state/ReportingContainer"
import Reporting from "./Reporting"


class Dashboard extends React.Component{
            
    render(){
        return(
            <AuthenticationWrapper>                
                <Subscribe to={[AccountsContainer,
                                ApplicationsContainer,
                                BrokerCompaniesContainer, 
                                RegistrationsContainer,
                                PolicyNumbersContainer,
                                EndorsementsContainer,
                                ReportingContainer]}>
                              {(accountsContainer,
                                applicationsContainer,
                                brokerCompaniesContainer,
                                registrationsContainer,
                                policyNumbersContainer,
                                endorsementsContainer,
                                reportingContainer) =>
                    
                        <Router history={history}>
                            <Switch>
                                <Route path="/" 
                                    exact
                                    render={()=> <Home 
                                        applicationsContainer={applicationsContainer}
                                        accountsContainer={accountsContainer}
                                        brokerCompaniesContainer={brokerCompaniesContainer}
                                        policyNumbersContainer={policyNumbersContainer} />} />
                                <Route path="/application/1" 
                                       exact
                                       render={()=> <Application1 
                                        applicationsContainer={applicationsContainer}
                                        brokerCompaniesContainer={brokerCompaniesContainer} 
                                        accountsContainer={accountsContainer} />} />
                                <Route path="/application/2" 
                                       exact
                                       render={()=> <Application2 
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/application/3" 
                                       exact
                                       render={()=> <Application3
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/application/bind" 
                                       exact
                                       render={()=> <Bind 
                                        applicationsContainer={applicationsContainer}/>} />
                                <Route path="/application/status" 
                                       exact
                                       render={()=> <ApplicationStatus 
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/agencies-and-contacts" 
                                    exact
                                    render={()=> <AgenciesAndContacts 
                                        brokerCompaniesContainer={brokerCompaniesContainer}
                                        accountsContainer={accountsContainer}
                                        registrationsContainer={registrationsContainer}
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/agencies-and-contacts/agency" 
                                    exact
                                    render={()=> <AddAgency brokerCompaniesContainer={brokerCompaniesContainer} />} />
                                <Route path="/agencies-and-contacts/contact" 
                                    exact
                                    render={()=> <AddContact 
                                        brokerCompaniesContainer={brokerCompaniesContainer}
                                        accountsContainer={accountsContainer} />} />
                                <Route path="/edit-registration" 
                                    exact
                                    render={()=> <EditRegistration
                                        accountsContainer={accountsContainer}
                                        brokerCompaniesContainer={brokerCompaniesContainer}
                                        registrationsContainer={registrationsContainer} />} />
                                <Route path="/gaig-users" 
                                        exact
                                        render={()=> <GaigUsers accountsContainer={accountsContainer} />} />
                                <Route path="/gaig-users/user" 
                                    exact
                                    render={()=> <AddUser accountsContainer={accountsContainer} />} />
                                <Route path="/reporting" 
                                        exact
                                        render={()=> <Reporting reportingContainer={reportingContainer} />} />
                                <Route path="/settings"
                                    exact
                                    render={()=> <Settings accountsContainer={accountsContainer} />} />
                                <Route path="/endorsements"
                                    exact
                                    render={()=> <Endorsements endorsementsContainer={endorsementsContainer} />} />
                                <Route path="/endorsements/endorsement" 
                                    exact
                                    render={()=> <AddEndorsement endorsementsContainer={endorsementsContainer} />} />
                                <Route path="/*" 
                                    exact
                                    render={()=> 
                                        <Home applicationsContainer={applicationsContainer}
                                              accountsContainer={accountsContainer}
                                              brokerCompaniesContainer={brokerCompaniesContainer}
                                              policyNumbersContainer={policyNumbersContainer} />} />
                            </Switch> 
                        </Router>
                }
                </Subscribe>                
            </AuthenticationWrapper>                  
        )
    }   
}

export default Dashboard