/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"
import { formatDate } from "../../helpers/FormatDate"

const componentStyle = css`
`
class AgencyApplication extends React.Component{ 

    state = {
        id: ""
    }

    componentDidMount = ()=>{
        const { application } = this.props
        this.setState({
            id: application.id
        })
    }

    continueApplication = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.continueApplication(this.state.id)
    }

    getApplicationPDF = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.getApplicationPDF(this.state.id)
    }

    getPolicyPDF = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.getPolicyPDF(this.state.id)
    }

    bindApplication = async ()=>{
        const { applicationsContainer } = this.props

        await applicationsContainer.initialize()
        
        await applicationsContainer.setState({
            currentApplicationId: this.state.id
        })

        history.push("application/bind")
    }

    getComments = async ()=>{
        const { applicationsContainer, toggleComments } = this.props
        
        toggleComments()

        applicationsContainer.getComments(this.state.id)
    }

    viewApplication = ()=>{
        const { viewApplication } = this.props
        viewApplication(this.state.id)
    }
        
    render(){
        const { applicationsContainer, application } = this.props

        const app = applicationsContainer.state.agencyApplicationsMap.get(application.id)

        return(            
            <tr css={componentStyle}>
                <td>{app.friendlyId}</td>
                <td>{app.namedInsured}</td>
                <td>{formatDate(app.dateStartedUtc)}</td>
                <td>{formatDate(app.dateSubmittedUtc)}</td>
                <td>{app.version}</td>
                <td>{app.status}</td>
                <td>{app.transaction}</td>
                <td><button className="anchor" onClick={this.viewApplication}>View</button></td>
                <td>
                    <button className="anchor"
                            onClick={this.getComments}>
                        Comments
                    </button>
                </td>
                <td>
                    {(application.status === "Approved" || 
                      application.status === "Approved to Bind" ||
                      application.status === "Bound") &&
                        <button className="anchor"
                                onClick={this.getApplicationPDF}>
                            View Quote
                        </button>
                    }
                </td>
                <td>
                    {application.status === "Bound" &&
                        <button className="anchor"
                                onClick={this.getPolicyPDF}>
                            View Policy
                        </button>
                    }
                </td>
            </tr>
        )
    }   
}

export default AgencyApplication