/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { testValidEmail } from "../../helpers/ValidateEmail"
import history from "../../components/History"

const componentStyle = css`
    button.search,
    button.add {
        margin-top: 24px !important;
    }
`
class AddUser extends React.Component{

    state = {
        id: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        role: "",
        error: "",
        validating: false
    }
    
    componentDidMount = async ()=>{
        window.scrollTo(0,0)

        const { accountsContainer } = this.props

        await accountsContainer.getAccountRoles()

        if(accountsContainer.state.editAccount){            
            const account = accountsContainer.state.editAccount

            this.setState({
                id: account.id,
                firstName: account.firstName,
                lastName: account.lastName,
                phoneNumber: account.phoneNumber,
                email: account.email,
                role: account.role
            })
        } 
    }

    updateValue = (e)=>{
        e = e.currentTarget

        this.setState({
            [e.name]: e.value
        })

        if(this.state.validating){
            this.validateForm()
        }
    }

    addAccount = async ()=>{
        if(!await this.validateForm()){
            return
        }

        const { accountsContainer } = this.props

        let result
        if(accountsContainer.state.editAccount){
            result = await accountsContainer.updateAccount(this.state)
        }else{
            result = await accountsContainer.addAccount(this.state)
        }

        if(!result?.succeeded){
            this.setState({
                error: result?.errorMessage || "Error",
            })
        }else{
            history.push("/gaig-users")
        }
    }

    validateForm = async ()=>{
        this.setState({
            validating: true
        })

        const notRequired = [
            "id",
            "brokerCompanyId",
            "error",
            "validating",
        ]

        let valid = true
        let error = "Please complete all required fields"

        for (const [key, value] of Object.entries(this.state)) {
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }

        if(valid){
            if(!testValidEmail(this.state.email)){
                valid = false
                error = "Please enter a valid email address"
            }
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }
    }

    cancel = async ()=>{
        // clear account state
        const { accountsContainer } = this.props

        await accountsContainer.setState({
            editAccount: null,
        })
        history.push("/gaig-users")
    }

    render(){
        const { accountsContainer } = this.props

        return(
            <>
            <div css={componentStyle}>
                <div className="row margin-top">
                    <div className="col">
                        <h2>{accountsContainer.state.editAccount ? "Edit" : "Add"}User</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="firstName"
                            className={(this.state.validating && !this.state.firstName) 
                                ? "required" : ""}>First Name:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="firstName" 
                                name="firstName"
                                value={this.state.firstName}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName"
                            className={(this.state.validating && !this.state.lastName) 
                            ? "required" : ""}>
                            Last Name:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="lastName" 
                                name="lastName"
                                value={this.state.lastName}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber"
                            className={(this.state.validating && !this.state.phoneNumber) 
                                ? "required" : ""}>
                            Mobile Phone Number:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="phoneNumber" 
                                name="phoneNumber"
                                value={this.state.phoneNumber}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email"
                            className={(this.state.validating && (!this.state.email || !testValidEmail(this.state.email))) 
                                ? "required" : ""}>
                                Email Address (Username):<span className="required">*</span>
                        </label>
                        <input type="email" 
                                className="form-control form-control-sm" 
                                id="email" 
                                name="email"
                                value={this.state.email}
                                onChange={this.updateValue}
                                required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="role"
                            className={(this.state.validating && !this.state.role) 
                                ? "required" : ""}>
                            Role:<span className="required">*</span>
                        </label>
                        <select className="form-control form-control-sm" 
                                id="role"
                                name="role"
                                value={this.state.role}
                                onChange={this.updateValue}>                            
                            <option value="">--- Select ---</option>
                            {accountsContainer.state.roles.map(role =>(
                                (role.name !== "None" &&
                                <option key={role.id}
                                        value={role.id}>
                                    {role.name}
                                </option>
                                )                                
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {this.state.error !== "" &&
                <div className="row">
                    <div className="col-sm-6">
                        <p className="error">{this.state.error}</p>
                    </div>
                </div>
            }

            <div className="row button-group">
                <div className="col-sm-6">
                    <button className="btn btn-sm btn-primary float-right"
                            onClick={this.addAccount}
                            disabled={accountsContainer.state.isLoading}>Submit</button>
                    <button className="btn btn-sm btn-secondary float-right"
                            onClick={this.cancel}
                            disabled={accountsContainer.state.isLoading}>Cancel</button>
                    
                </div>
            </div>
            </>
        )
    }   
}

export default AddUser