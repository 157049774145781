/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { testValidEmail } from "../../helpers/ValidateEmail";

class EditContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    brokerCompanyId: "",
    admittedProducerCode: "",
    nyftzProducerCode: "",
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    emailValidationMessage: "",
    phoneValidationMessage: "",
    isEmailValid: true,
  });

  static getDerivedStateFromProps(nextProps, prevState) {
    const { accountsContainer } = nextProps;
    const account = accountsContainer.state.editAccount;

    if (account && account.id !== prevState.id) {
      return {
        brokerCompanyId: account.brokerCompanyId,
        admittedProducerCode: account.admittedProducerCode,
        nyftzProducerCode: account.nyftzProducerCode,
        id: account.id,
        firstName: account.firstName,
        lastName: account.lastName,
        phoneNumber: account.phoneNumber,
        email: account.email,
        emailValidationMessage: "",
        phoneValidationMessage: "",
        isEmailValid: true,
      };
    }
    return null;
  }

  onClose = (isEdited) => {
    const { accountsContainer, onHide } = this.props;
    accountsContainer.setState({
      editAccount: null,
    });
    this.setState(this.getInitialState());
    onHide(isEdited);
  };

  handleSubmit = async () => {
    if (this.state.email === "" && this.state.isEmailValid) {
      this.setState({ emailValidationMessage: "Please enter email." });
    } else if (this.state.phoneNumber === "") {
      this.setState({ phoneValidationMessage: "Please enter phone number." });
    } else {
      const { accountsContainer } = this.props;
      const result = await accountsContainer.updateAccount(this.state);
      if (!result?.succeeded) {
        console.log("There was an error updating the account");
        return;
      } else {
        this.onClose(true);
      }
    }
  };

  render() {
    const { show } = this.props;
    const {
      phoneValidationMessage,
      emailValidationMessage,
      email,
      phoneNumber,
      admittedProducerCode,
      nyftzProducerCode,
    } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          this.onClose(false);
        }}
        centered
        key={this.state.id}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Update User Information to Reassign Broker Contacts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="phoneNumber">
              <Form.Label>
                Mobile Phone Number:<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mobile Phone Number"
                value={phoneNumber}
                onChange={(e) =>
                  this.setState({
                    phoneNumber: e.target.value,
                    phoneValidationMessage: "",
                  })
                }
              />
              {phoneValidationMessage && (
                <Form.Text className="text-danger">
                  {phoneValidationMessage}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>
                Email Address (Username):<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                  if (!testValidEmail(e.target.value)) {
                    this.setState({
                      isEmailValid: false,
                      emailValidationMessage:
                        "Please enter a valid email address",
                    });
                  } else {
                    this.setState({
                      emailValidationMessage: "",
                      isEmailValid: true,
                    });
                  }
                }}
              />
              {emailValidationMessage && (
                <Form.Text className="text-danger">
                  {emailValidationMessage}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="admittedProducerCode">
              <Form.Label>GAIG Admitted Producer Code:</Form.Label>
              <Form.Control
                type="text"
                value={admittedProducerCode}
                placeholder="Enter GAIG Admitted Producer Code"
                onChange={(e) =>
                  this.setState({ admittedProducerCode: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="nyftzProducerCode">
              <Form.Label>NYFTZ Producer Code:</Form.Label>
              <Form.Control
                type="text"
                value={nyftzProducerCode}
                placeholder="Enter NYFTZ Producer Code"
                onChange={(e) =>
                  this.setState({ nyftzProducerCode: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              this.onClose(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary"
            style={{ backgroundColor: "#7694aa", borderColor: "#7694aa" }}
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditContactModal;
