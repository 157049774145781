/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import DatePicker from "react-datepicker"
import { sortableHandle } from "react-sortable-hoc"

const DragHandle = sortableHandle(() => {
    return(
        <i className="fas fa-arrows-alt" />
    )
})

const componentStyle = css`
    i  {
        cursor: pointer;
    }
`
class UndergroundStorageTank extends React.Component{

    state = {
        id: null,
        locationId: "None",
        tankAgeInNumber: "",
        construction: "None",
        otherConstruction: "",
        capacityInNumber: "",
        contents: "None",
        otherContents: "",
        typeOfLeakDetection: "None",
        otherTypeOfLeakDetection: "",
        retroactiveDateOnExistingPolicyUtc: null,
        deductible: "None",
        tankTightnessTestPassed: "",
        pipeLineConstruction: "None",
        pipeAgeInNumber: "",
        pipeLeakDetection: "",
        leakDetection: ""
      }

    componentDidMount = async ()=>{        
        const { undergroundStorageTank, applicationsContainer } = this.props
        const ugst = applicationsContainer.state.undergroundStorageTanksMap.get(undergroundStorageTank)

        await this.setState({
            id: ugst.id,
            locationId: ugst.locationId ? ugst.locationId : "None",
            tankAgeInNumber: ugst.tankAgeInNumber ? ugst.tankAgeInNumber : "",
            construction: ugst.construction ? ugst.construction : "None",
            otherConstruction: ugst.otherConstruction,
            capacityInNumber: ugst.capacityInNumber ? ugst.capacityInNumber : "",
            contents: ugst.contents ? ugst.contents : "None",
            otherContents: ugst.otherContents,
            typeOfLeakDetection: ugst.typeOfLeakDetection ? ugst.typeOfLeakDetection : "None",
            otherTypeOfLeakDetection: ugst.otherTypeOfLeakDetection,
            retroactiveDateOnExistingPolicyUtc: ugst.retroactiveDateOnExistingPolicyUtc ? new Date(ugst.retroactiveDateOnExistingPolicyUtc) : null,
            deductible: ugst.deductible ? ugst.deductible : "None",
            tankTightnessTestPassed: ugst.tankTightnessTestPassed ? ugst.tankTightnessTestPassed.toLowerCase() : "",
            pipeLineConstruction: ugst.pipeLineConstruction ? ugst.pipeLineConstruction : "None",
            pipeAgeInNumber: ugst.pipeAgeInNumber ? ugst.pipeAgeInNumber : "",
            pipeLeakDetection: ugst.pipeLeakDetection ? ugst.pipeLeakDetection.toLowerCase() : ""
        })
        
        if(!ugst.retroactiveDateOnExistingPolicyUtc){
            this.setDate(new Date(applicationsContainer.state.currentApplication.dateEffectiveUtc), "retroactiveDateOnExistingPolicyUtc")
        }        
    }

    updateValue = async (e)=>{
        e = e.currentTarget
        let value = e.value
        let name = e.name

        if(name.includes("pipeLeakDetection")){
            name = "pipeLeakDetection"
        }else if(name.includes("tankTightnessTestPassed")){
            name = "tankTightnessTestPassed"
        }

        await this.setState({
            [name]: value
        })

        if(name === "construction" && value !== "Other"){
            await this.setState({
                otherConstruction: ""
            })
        }else if(name === "contents" && value !== "Other"){
            await this.setState({
                otherContents: ""
            })
        }else if(name === "typeOfLeakDetection" && value !== "Other"){
            await this.setState({
                otherTypeOfLeakDetection: ""
            })
        }

        if(name === "locationId" ||
           name === "construction" ||
           name === "contents" ||
           name === "typeOfLeakDetection" ||
           name === "deductible" ||
           name === "pipeLineConstruction" ||
           name === "pipeLeakDetection" ||
           name === "tankTightnessTestPassed"){ 
            this.updateStorageTank()
        }
    }

    setDate = async (date, name)=>{
        await this.setState({
            [name]: date
        })
        this.updateStorageTank()
    }
    
    updateStorageTank = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateUndergroundStorageTank(this.state)
    }

    deleteStorageTank = async ()=>{
        const { applicationsContainer } = this.props
        let confirm = window.confirm("Are you sure you want to delete this Storage Tank?")
        if(confirm){
            await applicationsContainer.deleteUndergroundStorageTanks([this.state.id])
        }
    }

    cloneStorageTank = async ()=>{
        const { applicationsContainer } = this.props
        await applicationsContainer.cloneUndergroundStorageTank(this.state.id)
    }
      
    render(){
        const { applicationsContainer } = this.props
        const isValidating = applicationsContainer.state.isValidating

        return(            
            <tr css={componentStyle}>
                <td className="action"><DragHandle /></td>
                <td>
                    <select className={"form-control form-control-sm" + ((isValidating && this.state.locationId === "None") ? " required" : "")} 
                            id="locationId"
                            name="locationId"
                            onChange={this.updateValue}
                            value={this.state.locationId}> 
                        <option value="None">Select</option>
                        {applicationsContainer.state.locations.map((location, index)=>(
                            (applicationsContainer.state.locationsMap.get(location).address &&
                                <option key={index}
                                        value={location}>
                                    {applicationsContainer.state.locationsMap.get(location).address}
                                </option>
                            ))
                        )}
                    </select>
                </td>
                <td>
                <input type="number" 
                       className={"form-control form-control-sm" + ((isValidating && (!this.state.tankAgeInNumber || this.state.tankAgeInNumber === "0")) ? " required" : "")}
                       id="tankAgeInNumber"
                       name="tankAgeInNumber"
                       value={this.state.tankAgeInNumber}
                       onChange={this.updateValue}
                       onBlur={this.updateStorageTank} />

                </td>
                <td>
                    <div className="form-group">
                    <select className={"form-control form-control-sm" + ((isValidating && this.state.construction === "None") ? " required" : "")}
                                id="construction"
                                name="construction"
                                onChange={this.updateValue}
                                value={this.state.construction}> 
                            <option value="None">Select</option>
                            {applicationsContainer.state.undergroundConstructions.map((construction, index)=>(
                                (construction.id !== "None" &&
                                    <option key={index}
                                            value={construction.id}>
                                        {construction.name}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>
                    {this.state.construction === "Other" &&
                        <div className="form-group">
                            <input type="text" 
                                    className={"form-control form-control-sm" + ((isValidating && this.state.otherConstruction === "") ? " required" : "")}
                                    id="otherConstruction" 
                                    name="otherConstruction"
                                    onChange={this.updateValue}
                                    onBlur={this.updateStorageTank}
                                    value={this.state.otherConstruction}
                                    placeholder="Enter Construction" />
                        </div>
                    }
                </td>
                <td>
                <input type="number" 
                       className={"form-control form-control-sm" + ((isValidating && (!this.state.capacityInNumber || this.state.capacityInNumber === "0")) ? " required" : "")}
                       id="capacityInNumber"
                       name="capacityInNumber"
                       value={this.state.capacityInNumber}
                       onChange={this.updateValue}
                       onBlur={this.updateStorageTank} />
                </td>
                <td>
                    <div className="form-group">
                    <select className={"form-control form-control-sm" + ((isValidating && this.state.contents === "None") ? " required" : "")}
                                id="contents"
                                name="contents"
                                onChange={this.updateValue}
                                value={this.state.contents}> 
                            <option value="None">Select</option>
                            {applicationsContainer.state.undergroundContents.map((content, index)=>(
                                (content.id !== "None" &&
                                    <option key={index}
                                            value={content.id}>
                                        {content.name}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>
                    {this.state.contents === "Other" &&
                        <div className="form-group">
                            <input type="text" 
                                    className={"form-control form-control-sm" + ((isValidating && this.state.otherContents === "") ? " required" : "")} 
                                    id="otherContents" 
                                    name="otherContents"
                                    onChange={this.updateValue}
                                    onBlur={this.updateStorageTank}
                                    value={this.state.otherContents}
                                    placeholder="Enter Contents" />
                        </div>
                    }
                </td>
                <td>
                    <div className="form-group">
                        <select className={"form-control form-control-sm" + ((isValidating && this.state.typeOfLeakDetection === "None") ? " required" : "")}
                                id="typeOfLeakDetection"
                                name="typeOfLeakDetection"
                                onChange={this.updateValue}
                                value={this.state.typeOfLeakDetection}> 
                            <option value="None">Select</option>
                            {applicationsContainer.state.undergroundLeakDetectionTypes.map((leakDetectionType, index)=>(
                                (leakDetectionType.id !== "None" &&
                                    <option key={index}
                                            value={leakDetectionType.id}>
                                        {leakDetectionType.name}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        {this.state.typeOfLeakDetection === "Other" &&
                            <div className="form-group">
                                <input type="text" 
                                        className={"form-control form-control-sm" + ((isValidating && this.state.otherTypeOfLeakDetection === "") ? " required" : "")} 
                                        id="otherTypeOfLeakDetection" 
                                        name="otherTypeOfLeakDetection"
                                        onChange={this.updateValue}
                                        onBlur={this.updateStorageTank}
                                        value={this.state.otherTypeOfLeakDetection}
                                        placeholder="Enter Leak Detection" />
                            </div>
                        }
                    </div>
                </td>
                <td>
                    <DatePicker popperPlacement="bottom-end"
                                dateFormat="MM/dd/yyyy"
                                className="form-control form-control-sm"
                                id="retroactiveDate"
                                name="retroactiveDate"
                                selected={this.state.retroactiveDateOnExistingPolicyUtc ?
                                    this.state.retroactiveDateOnExistingPolicyUtc :
                                    new Date(applicationsContainer.state.currentApplication.dateEffectiveUtc)
                                }
                                maxDate={new Date(applicationsContainer.state.currentApplication.dateEffectiveUtc)}
                                onChange={(date)=>{this.setDate(date, "retroactiveDateOnExistingPolicyUtc")}} />
                </td>
                <td>
                    <div className="form-group">
                        <select className={"form-control form-control-sm" + ((isValidating && this.state.deductible === "None") ? " required" : "")}
                                id="deductible"
                                name="deductible"
                                onChange={this.updateValue}
                                value={this.state.deductible}> 
                            <option value="None">Select</option>
                            {applicationsContainer.state.undergroundDeductibles.map((deductible, index)=>(
                                (deductible.id !== "None" &&
                                    <option key={index}
                                            value={deductible.id}>
                                        {deductible.name}
                                    </option>
                                )
                            ))}
                        </select>
                    </div>
                </td>
                <td>
                    <div className="form-check">
                        <input className="form-check-input" 
                               type="radio" 
                               id={"tankTightnessTestPassed-"+this.state.id+"-Yes"}
                               name={"tankTightnessTestPassed-"+this.state.id}                               
                               value="yes"
                               checked={this.state.tankTightnessTestPassed === "yes"}
                               onChange={this.updateValue} />
                        <label className={"form-check-label" + ((isValidating && this.state.tankTightnessTestPassed === "") ?" required" : "")}
                               htmlFor={"tankTightnessTestPassed-"+this.state.id+"-Yes"}>
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" 
                               type="radio" 
                               id={"tankTightnessTestPassed-"+this.state.id+"-No"}
                               name={"tankTightnessTestPassed-"+this.state.id}                               
                               value="no"
                               checked={this.state.tankTightnessTestPassed === "no"}
                               onChange={this.updateValue} />
                        <label className={"form-check-label" + ((isValidating && this.state.tankTightnessTestPassed === "") ?" required" : "")}
                               htmlFor={"tankTightnessTestPassed-"+this.state.id+"-No"}>
                            No
                        </label>
                    </div>
                </td>
                <td>
                    <select className={"form-control form-control-sm" + ((isValidating && this.state.pipeLineConstruction === "None") ? " required" : "")}
                            id="pipeLineConstruction"
                            name="pipeLineConstruction"
                            value={this.state.pipeLineConstruction}
                            onChange={this.updateValue}> 
                        <option value="None">Select</option>
                        {applicationsContainer.state.undergroundPipeLineConstructions.map((pipelineConstruction, index)=>(
                                (pipelineConstruction.id !== "None" &&
                                    <option key={index}
                                            value={pipelineConstruction.id}>
                                        {pipelineConstruction.name}
                                    </option>
                                )
                            ))}
                    </select>
                </td>
                <td>
                    <input type="number" 
                        className={"form-control form-control-sm" + ((isValidating && (!this.state.pipeAgeInNumber || this.state.pipeAgeInNumber === "0")) ? " required" : "")}
                        id="pipeAgeInNumber"
                        name="pipeAgeInNumber"
                        value={this.state.pipeAgeInNumber}
                        onChange={this.updateValue}
                        onBlur={this.updateStorageTank} />
                </td>
                <td>
                    <div className="form-check">
                        <input className="form-check-input" 
                               type="radio" 
                               id={"pipeLeakDetection-"+this.state.id+"-Yes"}
                               name={"pipeLeakDetection-"+this.state.id}                               
                               value="yes"
                               checked={this.state.pipeLeakDetection === "yes"}
                               onChange={this.updateValue} />
                        <label className={"form-check-label" + ((isValidating && this.state.pipeLeakDetection === "") ?" required" : "")}
                               htmlFor={"pipeLeakDetection-"+this.state.id+"-Yes"}>
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" 
                               type="radio" 
                               id={"pipeLeakDetection-"+this.state.id+"-No"}
                               name={"pipeLeakDetection-"+this.state.id}                               
                               value="no"
                               checked={this.state.pipeLeakDetection === "no"}
                               onChange={this.updateValue} />
                        <label className={"form-check-label" + ((isValidating && this.state.pipeLeakDetection === "") ?" required" : "")}
                               htmlFor={"pipeLeakDetection-"+this.state.id+"-No"}>
                            No
                        </label>
                    </div>
                </td>
                <td className="action">
                    <button className="anchor"
                            onClick={this.cloneStorageTank}
                            disabled={applicationsContainer.state.isLoading}>
                        <i className="fa fa-clone"/>
                    </button>                    
                </td>
                <td className="action">
                    <button className="anchor"
                            onClick={this.deleteStorageTank}
                            disabled={applicationsContainer.state.isLoading}>
                        <i className="fa fa-trash-o"/>
                    </button>
                </td>
            </tr>
        )
    }   
}

export default UndergroundStorageTank