import { Container } from "unstated";
import * as api from "../api";
import update from "immutability-helper";

class BrokerCompaniesContainer extends Container {
  state = {
    isLoading: false,
    warnings: [],
    warningHeader: "",
    warningFooter: "",
    entityTypes: [],
    brokerCompanies: [],
    agencyId: null,
    validationErrors: [],
    brokerCompaniesSearchResult: [],
    agencyApplications: [],
    editAgency: null,
    editAccount: null,
    brokerCompaniesForDeletion: null,
    accountsForDeletion: null,
    searchTerm: "",
    searchIn: "Agency",
  };

  initialize = async () => {
    this.setState({
      isLoading: true,
    });

    await this.getEntityTypes();
    await this.getBrokerCompaniesList();

    this.setState({
      isLoading: false,
      editAgency: null,
      editAccount: null,
      brokerCompaniesForDeletion: new Set(),
      contactsForDeletion: new Set(),
    });
  };

  checkResult = async (result) => {
    if (!result) {
      await this.setState({
        warnings: "There was an unexpected problem. Please try again.",
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return false;
    }

    if (!result?.succeeded) {
      await this.setState({
        warnings:
          result?.warnings && result?.warnings?.length > 0
            ? result.warnings
            : ["There was an unexpected problem. Please try again."],
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return false;
    }
    if (result?.warnings?.length > 0) {
      await this.setState({
        warnings: result.warnings,
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return result.succeeded;
    }

    return true;
  };

  getEntityTypes = async () => {
    const result = await api.getEntityTypes();

    this.setState({
      entityTypes: result.items,
    });
  };

  getBrokerCompaniesList = async () => {
    const result = await api.getBrokerCompaniesList();

    await this.setState({
      brokerCompanies: result.items,
    });
  };

  getBrokerCompany = async (id, populateResults) => {
    const result = await api.getBrokerCompany(id);

    if (!this.checkResult(result)) {
      return;
    }

    if (!populateResults) {
      await this.setState({
        editAgency: result,
      });
    } else {
      await this.setState({
        brokerCompaniesSearchResult: [result],
        searchTerm: "",
      });
    }

    return result;
  };

  addBrokerCompany = async (data) => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      name: data.name,
      typeOfEntity: data.typeOfEntity,
      admittedProducerCode: data.admittedProducerCode,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      mainPhone: data.mainPhone,
      website: data.website,
      commission: data.commission,
    };

    const result = await api.addBrokerCompany(payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    await this.initialize();

    return result;
  };

  updateBrokerCompany = async (data) => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      name: data.name,
      typeOfEntity: data.typeOfEntity,
      admittedProducerCode: data.admittedProducerCode,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      mainPhone: data.mainPhone,
      website: data.website,
      commission: data.commission,
    };

    const result = await api.updateBrokerCompany(data.id, payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    await this.initialize();

    return result;
  };

  searchBrokerCompanies = async (
    searchTerm,
    searchIn,
    currentPage,
    recordsPerPage,
    orderBy,
    descending
  ) => {
    searchTerm = searchTerm
      ? searchTerm
      : this.state.searchTerm
      ? this.state.searchTerm
      : "";

    this.setState({
      isLoading: true,
      searchTerm: searchTerm,
    });

    if (!searchTerm) {
      this.setState({
        brokerCompaniesSearchResult: [],
        isLoading: false,
      });
      return;
    }

    const result = await api.searchBrokerCompanies(
      searchTerm,
      searchIn,
      currentPage,
      recordsPerPage,
      orderBy,
      descending
    );
    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      brokerCompaniesSearchResult: result.records,
      isLoading: false,
    });

    return result.totalRecords;
  };

  deleteBrokerCompanies = async (brokerCompanies) => {
    if (!brokerCompanies.length) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    const result = await api.deleteBrokerCompanies(brokerCompanies);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
  };

  deleteAccounts = async (accounts) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.deleteAccounts(accounts);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
  };

  markBrokerCompanyForDeletion = async (id, checked) => {
    const brokerCompaniesForDeletion = this.state.brokerCompaniesForDeletion;
    let newBrokerCompaniesForDeletion;
    if (checked) {
      newBrokerCompaniesForDeletion = update(brokerCompaniesForDeletion, {
        $add: [id],
      });
    } else {
      newBrokerCompaniesForDeletion = update(brokerCompaniesForDeletion, {
        $remove: [id],
      });
    }

    await this.setState({
      brokerCompaniesForDeletion: newBrokerCompaniesForDeletion,
    });
  };

  markBrokerAccountForDeletion = async (id, checked) => {
    const accountsForDeletion = this.state.accountsForDeletion;
    let newAccountsForDeletion;
    if (checked) {
      newAccountsForDeletion = update(accountsForDeletion, { $add: [id] });
    } else {
      newAccountsForDeletion = update(accountsForDeletion, { $remove: [id] });
    }

    await this.setState({
      accountsForDeletion: newAccountsForDeletion,
    });
  };

  deleteSelectedBrokerCompanies = async (
    searchTerm,
    currentPage,
    recordsPerPage,
    orderBy,
    sort
  ) => {
    this.setState({
      isLoading: true,
    });

    await this.deleteBrokerCompanies(
      Array.from(this.state.brokerCompaniesForDeletion)
    );

    await this.initialize();

    await this.searchBrokerCompanies(
      searchTerm,
      currentPage,
      recordsPerPage,
      orderBy,
      sort
    );

    this.setState({
      isLoading: false,
    });
  };

  exportBrokerCompanies = async () => {
    this.setState({
      isLoading: true,
    });

    const result = await api.exportBrokerCompanies();

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    return result;
  };
}

export default BrokerCompaniesContainer;
