/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { testValidEmail } from "../helpers/ValidateEmail"
import { testValidPassword } from "../helpers/ValidatePassword"

const componentStyle = css`
    input.password{
        margin-top: 5px;
    }
`
class Settings extends React.Component{

    state = {
        id: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        editMode: false,
        password: "",
        confirmPassword: "",
        error: "",
        isValidating: false
    }

    componentDidMount =  async ()=>{
        const { accountsContainer } = this.props
        await accountsContainer.getCurrentAccount()
        
        const account = accountsContainer.state.currentAccount

        this.setState({
            id: account.id,
            firstName: account.firstName,
            lastName: account.lastName,
            phoneNumber: account.phoneNumber,
            email: account.email
        })       
    }

    toggleEditMode = ()=>{
        this.setState({
            editMode: this.state.editMode ? false : true,
            isValidating: false
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget

        let value = e.value

        await this.setState({
            [e.name]: value
        })

        if(this.state.isValidating){
            this.validateForm()
        }
    }

    updateAccount = async ()=>{
        if(!await this.validateForm()){
            return
        }

        const { accountsContainer } = this.props
            
        let result = await accountsContainer.updateAccount(this.state)

        if(!result?.succeeded){
            this.setState({
                error: result?.errorMessage|| "Error",
            })
            return
        }

        if(this.state.password && this.state.confirmPassword){
            result = await accountsContainer.updatePassword(this.state)

            if(!result?.succeeded){
                this.setState({
                    error: "There was an error. Please try again.",
                    isValidating: false
                })
                return
            }
        }

        await this.setState({
            password: "",
            confirmPassword: "",
            isValidating: false
        })

        await accountsContainer.getCurrentAccount()

        this.toggleEditMode()
    }

    validateForm = ()=>{
        this.setState({
            isValidating: true
        })

        const notRequired = [
            "id",
            "password",
            "confirmPassword",
            "error",
            "isValidating"
        ]

        let valid = true
        let error = "Please complete required all fields"

        for (const [key, value] of Object.entries(this.state)) {
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }
        
        if(this.state.password && !this.state.confirmPassword){
            valid = false
        }
        
        if(valid){
            if(!testValidEmail(this.state.email)){
                valid = false
                error = "Please enter a valid email address"
            }
        }

        if(valid && this.state.password){
            if(!testValidPassword(this.state.password)){
                valid = false
                error = "Passwords must be 8 OR MORE characters, contain an uppercase character, a lowercase character, a digit, and a non-alphanumeric character"
            }
        }

        if(valid && this.state.password){
            if(this.state.password !== this.state.confirmPassword){
                valid = false
                error = "Passwords do not match"
            }
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }       
    }
        
    render(){
        const { accountsContainer } = this.props
        const editAccount = accountsContainer.state.editAccount

        return(
            <div css={componentStyle}>
               
                <div className="row">
                    <div className="col">
                        <h2>Settings</h2>
                        {!this.state.editMode &&
                            <p className="directions">
                                Click edit to update your information below:
                            </p>
                        }
                    </div>
                </div>
                    
                {!this.state.editMode &&
                <>
                    <div className="row">
                        <div className="col-sm-6">
                            <p>
                                First Name: {editAccount ? editAccount.firstName : ""}<br />
                                Last Name: {editAccount ? editAccount.lastName : ""}<br />
                                Email: {editAccount ? editAccount.email : ""}<br />
                                Mobile Phone Number: {editAccount ? editAccount.phoneNumber: ""}<br />
                                Password: **********
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <button className="btn btn-sm btn-primary"
                                    onClick={this.toggleEditMode}>
                                Edit
                            </button>
                        </div>
                    </div>
                    </>
                }

                {this.state.editMode &&
                    <>
                    <div className="row margin-top">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="firstName"
                                    className={(this.state.isValidating && !this.state.firstName) 
                                        ? "required" : ""}>First Name:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="firstName" 
                                        name="firstName"
                                        value={this.state.firstName}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName"
                                    className={(this.state.isValidating && !this.state.lastName) 
                                    ? "required" : ""}>
                                    Last Name:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="lastName" 
                                        name="lastName"
                                        value={this.state.lastName}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber"
                                    className={(this.state.isValidating && !this.state.phoneNumber) 
                                        ? "required" : ""}>
                                    Mobile Phone Number:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="phoneNumber" 
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"
                                       className={(this.state.isValidating && (!this.state.email || !testValidEmail(this.state.email))) 
                                        ? "required" : ""}>
                                        Email Address (Username):<span className="required">*</span>
                                </label>
                                <input type="email" 
                                        className="form-control form-control-sm" 
                                        id="email" 
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.updateValue}
                                        required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password"
                                       className={(this.state.isValidating && this.state.password && !testValidPassword(this.state.password)) 
                                        ? "required" : ""}>
                                    Password:
                                </label>
                                <div className="small"><strong>Hint:</strong> 8 or more characters, contain an uppercase,  character, contain a lowercase character, contain a number, and contain a non-alphanumeric character</div>
                                <input type="password" 
                                        className="form-control form-control-sm password" 
                                        id="password" 
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword"
                                       className={
                                        (this.state.isValidating && this.state.password) &&
                                        ((this.state.password !== this.state.confirmPassword) ||
                                        !this.state.confirmPassword)
                                        
                                        ? "required" : ""}>
                                    Confirm Password:{(this.state.password) && <span className="required">*</span>
                                    }
                                </label>
                                <input type="password" 
                                        className="form-control form-control-sm" 
                                        id="confirmPassword" 
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.updateValue} />
                            </div>
                        </div>
                    </div>

                    {this.state.error !== "" &&
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="error">{this.state.error}</p>
                            </div>
                        </div>
                    }

                    <div className="row button-group">
                        <div className="col-sm-6">                            
                            <button className="btn btn-sm btn-primary float-right"
                                    onClick={this.updateAccount}>
                                Submit
                            </button>
                            <button className="btn btn-sm btn-secondary float-right"
                                    onClick={this.toggleEditMode}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    </>
                }
            </div>
        )
    }   
}

export default Settings