/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"

const componentStyle = css`
    h2 {
        margin-bottom: 15px;
    }
`
class TwoFactorLogin extends React.Component {
    state = {
        twoFactorCode: "",
        error: "",
        codeResent: false
    }

    updateValue = (e) => {
        e = e.currentTarget
        this.setState({
            [e.name]: e.value
        })
    }

    loginWith2fa = async () => {
        const { authenticationContainer } = this.props

        const result = await authenticationContainer.loginWith2fa(this.state.twoFactorCode)

        if (result !== true) {
            this.setState({
                error: result
            })
        }
    }

    resendCode = async () => {
        this.setState({
            isLoading: true,
            codeResent: false
        })
        const { authenticationContainer } = this.props
        const result = await authenticationContainer.resend2faToken()

        if (result.succeeded !== true) {
            this.setState({
                error: "There was an error please try again"
            })
        } else {
            this.setState({
                isLoading: false,
                codeResent: true
            })
        }
    }

    render() {
        const { authenticationContainer } = this.props

        return (
            <>
                <div
                    className="row"
                    css={componentStyle}>
                    <div className="col">
                        <h2>Two Factor Login</h2>
                        <p>
                            A code has been sent to the email address on file. Please check your email and enter your
                            security code in the space provided below.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="twoFactorCode">Code:</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="twoFactorCode"
                                name="twoFactorCode"
                                value={this.state.username}
                                onChange={this.updateValue}
                            />
                        </div>
                    </div>
                </div>

                {this.state.error !== "" && (
                    <div className="row">
                        <div className="col-4">
                            <p className="error">{this.state.error}</p>
                        </div>
                    </div>
                )}

                <div className="row button-group">
                    <div className="col-4">
                        <button
                            className="btn btn-sm btn-primary float-right"
                            onClick={this.loginWith2fa}
                            disabled={authenticationContainer.state.isLoading}>
                            Submit
                        </button>
                    </div>
                </div>

                {this.state.codeResent && (
                    <div className="row">
                        <div className="col">
                            <p>Code resent</p>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col">
                        <button
                            className="anchor"
                            onClick={this.resendCode}>
                            Resend Code
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

export default TwoFactorLogin
