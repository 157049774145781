/** @jsxImportSource @emotion/react */
import React, { Component } from "react";
import { css } from "@emotion/react/macro";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  InsertFile,
  SelectAll,
  Print,
  Pdf,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const componentStyle = css`
  h4 {
    float: left;
  }

  button {
    border: none;
    padding: 0 2px;
    background: none;
    outline: none;
    width: 28px;
    text-align: left;
    font-size: 18px;
    line-height: 18px;
    margin-top: -2px;
    height: auto;
    float: left;
  }

  .k-editor {
    margin-top: 10px;
    height: 275px;
  }

  .ProseMirror {
    padding: 10px;
    height: 100%;
    overflow-y: auto;
  }

  .ProseMirror-focused {
    border: none;
    outline: none;
  }

  /* Fixes Firefox space issue in Telerik */
  .k-content {
    white-space: pre-wrap;
  }

  /* Fixes table conflicts */
  .k-editor table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .k-editor table td {
    border: solid 1px rgb(221, 221, 221);
    width: auto;
    vertical-align: top;
  }

  /* Sticky toolbar */
  .k-editor-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
  }
`;

class RichTextEditor extends Component {
  updateValue = () => {
    const { updateValue } = this.props;
    const view = this.editor.view;

    updateValue(EditorUtils.getHtml(view.state));
  };

  handleFocus = () => {
    if (this.props.handleFocus) {
      this.props.handleFocus();
    }
  };

  render() {
    const { content } = this.props;

    return (
      <div css={componentStyle}>
        <div className="row">
          <div className="col">
            <Editor
              tools={[
                [Bold, Italic, Underline, Strikethrough],
                [Subscript, Superscript],
                ForeColor,
                BackColor,
                [CleanFormatting],
                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                [Indent, Outdent],
                [OrderedList, UnorderedList],
                FontSize,
                FontName,
                FormatBlock,
                [SelectAll],
                [Undo, Redo],
                [Link, Unlink, InsertImage, ViewHtml],
                [InsertTable, InsertFile],
                [Pdf, Print],
                [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                [DeleteRow, DeleteColumn, DeleteTable],
                [MergeCells, SplitCell],
              ]}
              key={this.props.content}
              contentStyle={{ height: "100%" }}
              defaultContent={content}
              defaultEditMode="div"
              onBlur={this.updateValue}
              onFocus={this.handleFocus}
              ref={(editor) => (this.editor = editor)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RichTextEditor;
