/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { Link } from "react-router-dom"
import React from "react"
import Spinner from "../components/Spinner"
import { Button, Modal } from "react-bootstrap"

const componentStyle = css`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
    margin: 0;
    padding: 0;
    display: block;
    margin-left: -15px;
    border-bottom: solid 2px #cc0000;
    background: #fff;

    header {
        color: #cc0000;
        width: 100%;
        padding: 5px 15px;
        display: block;
        height: 75px;
    }

    header img {
        width: 200px;
        height: auto;
        float: left;
    }

    header img.tankAssure {
        width: 200px;
        height: auto;
        float: right;
        margin-top: 19px;
    }

    header h1 {
        float: left;
        clear: none;
        display: inline-block;
        margin-top: 20px;
        width: calc(100% - 400px);
        text-align: center;
    }

    header .spinner {
        position: absolute;
        top: 23px;
        right: 240px;
    }

    nav {
        width: 100%;
        padding: 5px 15px 30px 15px !important;
        background: #cc0000;
    }

    nav ul,
    nav li {
        margin: 0;
        padding: 0;
        float: right;
        list-style-type: none;
    }

    nav li {
        margin-left: 20px;
    }

    nav a,
    nav button.anchor {
        font-size: 16px;
        color: #fff;
        text-decoration: none; /* Added to remove underline */
    }

    nav a:hover,
    nav button.anchor:hover {
        text-decoration: underline; /* Optional: Add underline on hover */
    }
`
class Layout extends React.Component {
    state = {
        showModal: false,
    }

    logout = () => {
        const { authenticationContainer } = this.props
        authenticationContainer.logout()
    }

    render() {
        const {
            authenticationContainer,
            brokerCompaniesContainer,
            accountsContainer,
            registrationsContainer,
            policyNumbersContainer,
            endorsementsContainer,
            applicationsContainer,
            reportingContainer,
        } = this.props

        return (
            <>
                <Modal
                    show={this.state.showModal}
                    onHide={() => {
                        this.setState({ showModal: false })
                    }}
                    centered
                    backdrop='static'
                    keyboard={false}
                >
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Leave Page</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Are you sure you want to leave this page? Any
                            changes made will not be saved.
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                this.setState({ showModal: false })
                            }}
                            className='btn btn-sm btn-secondary float-right'
                        >
                            Cancel
                        </Button>
                        <Button
                            className='btn btn-sm btn-danger float-right'
                            onClick={() => {
                                this.setState({ showModal: false })
                                accountsContainer.setState({
                                    editAccount: null,
                                })
                                window.location.href = "/agencies-and-contacts"
                            }}
                        >
                            Leave
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div css={componentStyle}>
                    <header>
                        <img
                            src='/img/gaig-logo.png'
                            alt='Great American Insurance Company - Environmental'
                        />
                        <img
                            src='/img/logo-tank-assure.png'
                            className='tankAssure'
                            alt='Tank Assure'
                        />
                        <h1>Storage Tank Policy Admin</h1>
                        {(authenticationContainer?.state?.isLoading ||
                            brokerCompaniesContainer?.state?.isLoading ||
                            accountsContainer?.state?.isLoading ||
                            registrationsContainer?.state?.isLoading ||
                            policyNumbersContainer?.state?.isLoading ||
                            endorsementsContainer?.state?.isLoading ||
                            applicationsContainer?.state?.isLoading ||
                            reportingContainer?.state?.isLoading) && (
                            <div className='spinner'>
                                <Spinner color='#cc0000' />
                            </div>
                        )}
                    </header>
                    <nav>
                        <ul>
                            {authenticationContainer?.state?.loggedIn &&
                                !accountsContainer?.state?.isInitalizing && (
                                    <>
                                        <li>
                                            <button
                                                className='anchor'
                                                onClick={this.logout}
                                            >
                                                Logout
                                            </button>
                                        </li>
                                        <li>
                                            <Link to='/settings'>Settings</Link>
                                        </li>
                                        <li>
                                            <Link to='/reporting'>Reports</Link>
                                        </li>
                                        {authenticationContainer?.state
                                            ?.accountRole === "Admin" && (
                                            <li>
                                                <Link to='/gaig-users'>
                                                    GAIG Users
                                                </Link>
                                            </li>
                                        )}
                                        <li>
                                            <Link
                                                to=''
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    // check if the href containst the /user in the url
                                                    if (
                                                        window.location.href.includes(
                                                            "/user"
                                                        )
                                                    ) {
                                                        this.setState({
                                                            showModal: true,
                                                        })
                                                    } else {
                                                        window.location.href =
                                                            "/agencies-and-contacts"
                                                    }
                                                }}
                                            >
                                                Agencies and Contacts
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/'>Home</Link>
                                        </li>
                                    </>
                                )}
                            {authenticationContainer?.state?.loggedIn ===
                                false && (
                                <>
                                    <li>
                                        <Link to='/login'>Login</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>
                </div>
            </>
        )
    }
}

export default Layout
