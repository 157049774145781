/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import User from "./User"
import history from "../../components/History"

const componentStyle = css`
`
class Users extends React.Component{

    state={
        searchTerm: ""
    }

    componentDidMount(){
        this.searchAccountAdmins()
    }

    searchAccountAdmins = async ()=>{
        const { accountsContainer } = this.props
        accountsContainer.searchAccountAdmins()
    }

    updateValue = (e)=>{
        e = e.currentTarget

        this.setState({
            [e.name]: e.value
        })

        const { accountsContainer } = this.props
        accountsContainer.setState({
            searchTerm: e.value
        })
    }

    addUser = async () =>{
        const { accountsContainer } = this.props
        await accountsContainer.setState({
            editAccount: false
        })
        
        history.push("/gaig-users/user")
    }

    deleteSelected = async () =>{
        const { accountsContainer } = this.props
        let confirm = window.confirm("Are you sure you want to delete the selected users?")
        if(confirm){
            accountsContainer.deleteSelectedAccounts()
        }
    }

    render(){
        const { accountsContainer } = this.props

        return(    
            <div css={componentStyle}>
                <div className="row button-group">
                    <div className="col-sm-12">                        
                        <button className="btn btn-sm btn-primary float-right add"
                                onClick={this.addUser}>
                            Add User
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>User Type</th>
                                    <th>Last Login Date Time</th>
                                    <th colSpan="2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountsContainer.state.accounts.map(account=>(
                                    <User key={account.id}
                                          accountsContainer={accountsContainer}
                                          user={account}/>                        
                                ))}
                                { accountsContainer.state.accounts.length === 0 &&
                                    <tr>
                                        <td colSpan="6">
                                            No Results
                                        </td>
                                    </tr>
                                }                             
                            </tbody>
                        </table>
                    </div>
                </div>

                { accountsContainer.state.accounts.length > 0 &&
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-sm btn-danger"
                                    onClick={this.deleteSelected}>
                                Delete Selected
                            </button>
                        </div>
                    </div>
                }
            </div>            
        )
    }   
}

export default Users