import { sendPost, sendGet } from "./generic"

export const getEntityTypes = ()=> {
    return sendGet("Registrations/EntityTypes")
}

export const register = (payload)=> {
    return sendPost("Registrations", payload)
}

export const getRegistrations = (page, numberOfRecords, orderBy, descending)=> {
    return sendGet("Registrations/"+page+"/"+numberOfRecords+"/"+orderBy+"/"+descending)
}

export const getRegistration = (id)=>{
    return sendGet("Registrations/"+id)
}

export const updateRegistration = (payload)=>{
    return sendPost("Registrations/"+payload.companyRegistrationId, payload)
}