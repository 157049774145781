import { Container } from "unstated"
import * as api from "../api"

class PolicyNumbersContainer extends Container {
    
    state = {
        isLoading: false,
        policyNumbersRemaining: 0
    }

    checkResult = async (result)=>{
        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result.warnings.length > 0){
            await this.setState({
                warnings: result?.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result?.succeeded
        }

        return true
    }

    getPolicyNumbersRemaining = async ()=>{
        this.setState({
            isLoading: true
        })

        const result = await api.getPolicyNumbersRemaining()  

        if(!this.checkResult(result)){
            return
        }
        
        this.setState({
            policyNumbersRemaining: result,
            isLoading: false
        })
    }

}

export default PolicyNumbersContainer