/** @jsxImportSource @emotion/react */
import React from "react"
import Modal from "../../components/Modal"

class StatusMessage extends React.Component{ 

    state={
        message: "",
        insured: "",
        status: "",
        saving: false
    }

    componentDidMount(){
        const { applicationsContainer } = this.props

        const status = applicationsContainer.state.applicationStatusesIdMap.get(applicationsContainer.state.currentApplicationStatus)
        

        const insured = applicationsContainer.state.attentionApplicationsMap.get(applicationsContainer.state.currentApplicationId).namedInsured + ", " + applicationsContainer.state.attentionApplicationsMap.get(applicationsContainer.state.currentApplicationId).brokerCompanyName

        this.setState({
            status: status,
            insured: insured
        })
    }

    updateValue = (e)=>{
        e = e.currentTarget

        this.setState({
            message: e.value
        })
    }

    postStatusMessage = async()=>{
        const { applicationsContainer, toggleStatusMessageOpen } = this.props

        if(!this.state.message){
            return
        }

        this.setState({
            saving: true
        })

        await applicationsContainer.updateApplicationStatus(this.state.message)

        toggleStatusMessageOpen()
        
        this.setState({
            saving: false
        })
    }

    render(){
        const { toggleStatusMessageOpen } = this.props
        return(
            <Modal close={toggleStatusMessageOpen}>
                <div className="row">
                    <div className="col">
                        <h3 className="margin-bottom">Change Status</h3>
                        <p>
                            {this.state.insured}<br/>
                            <strong>{this.state.status}</strong>
                        </p>
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col">
                        <label htmlFor="message">Message:</label>
                        <textarea className="form-control form-control-sm"
                                  id="message"
                                  name="message"
                                  placeholder=""
                                  rows="2"
                                  value={this.state.message}
                                  onChange={this.updateValue}
                                  disabled={this.state.saving}>
                        </textarea>
                    </div>
                </div>

                <div className="row button-group">
                    <div className="col">
                        <button className="btn btn-sm btn-primary float-right"
                                onClick={this.postStatusMessage}
                                disabled={this.state.saving}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }   
}

export default StatusMessage