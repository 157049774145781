/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { getRecordsPerPage } from "../../constants/Pagination"
import { formatDate } from "../../helpers/FormatDate"
import AttentionApplication from "./AttentionApplication"
import Pagination from "../../components/Pagination"
import TableSort from "../../components/TableSort"
import Modal from "../../components/Modal"
import Spinner from "../../components/Spinner"
import ReviewApplication from "./ReviewApplication"

const componentStyle = css`
    margin-top: 15px;

    .applicationData h3{
        width: 100%;
        border-bottom: solid 2px #cc0000;
        padding-bottom: 10px;
    }

    .applicationData h4{
        font-size: 16px;
        line-height: 16px;
        color: #7694aa;
        padding-bottom: 10px;
    }

    .applicationData table {
        margin-top: 0;
        border-top: none;
    }

    td.label{
        width: 250px;
        padding-right: 20px;
        text-align: right;
    }

    p.none-no-top-margin,
    ul.reasons{
        margin-top: -20px;
    }

    ul.reasons{
        margin-top: -10px;
    }
`
class AttentionApplications extends React.Component{ 

    state = {
        totalRecords: 0,
        recordsPerPage: getRecordsPerPage(),
        totalPages: 0,
        currentPage: 1,
        orderBy: "NamedInsuredName",
        sort: {
            FriendlyId: null,
            NamedInsured: "asc",
            BrokerCompanyName: null,
            AccountName: null,
            CustomerNumber: null,
            DateStarted: null,
            DateSubmitted: null,
            Version: null,
            Status: null,
            Transaction: null
        },
        viewApplicationVisible: false
    }

    componentDidMount = async ()=>{        
        const { applicationsContainer } = this.props
        await applicationsContainer.getActionableStatuses()
        this.getAttentionApplications()
    }

    getAttentionApplications = async()=>{
        const { applicationsContainer } = this.props
        const totalRecords = await applicationsContainer.getAttentionApplications(this.state.currentPage,
                                                                                  getRecordsPerPage(),
                                                                                  this.state.orderBy,
                                                                                  this.state.sort[this.state.orderBy] === "asc" ? true : false)
        await this.setState({
            totalRecords: totalRecords,
            totalPages: Math.ceil(totalRecords/this.state.recordsPerPage)
        })
    }

    sortTable = async (orderBy, direction)=>{
        let tempSort = {}
        
        for (const key of Object.keys(this.state.sort)) {
            tempSort[key] = null;
        }
        
        if(!direction){
            tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc"
        }else{
            tempSort[orderBy] = direction
        }    
                
        await this.setState({
            orderBy: orderBy,
            sort: tempSort,
            currentPage: 1
        })

        this.getAttentionApplications()
    }

    changePage = async (page)=>{
        await this.setState({
            currentPage: page
        })

        this.getAttentionApplications()
    }

    toggleViewApplication = ()=>{
        this.setState({
            viewApplicationVisible: this.state.viewApplicationVisible ? false : true
        })
    }

    viewApplication = async (id)=>{
        const { applicationsContainer } = this.props

        this.toggleViewApplication()

        await applicationsContainer.setState({
            currentApplicationId: id,
            currentApplication: null,
            coverages: [],
            locations: [],
            abovegroundStorageTanks: [],
            undergroundStorageTanks: [],
            requirementQuestions: [],
            additionalNamedInsured: [],
            additionalInsured: [],
            total: ""
        })

        await applicationsContainer.viewApplication(id)

        await applicationsContainer.setState({
            isLoading: false
        })       
    }

    render(){
        const { applicationsContainer, toggleComments, toggleStatusMessageOpen } = this.props
        const curApp = applicationsContainer.state.currentApplication

        return(
            <div css={componentStyle}>
                
                <h3>Applications Requiring Attention</h3>

                <div className="row">
                    <div className="col">
                        <table className="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("FriendlyId")}
                                                   direction={this.state.sort.FriendlyId}>
                                            ID Number
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("NamedInsured")}
                                                    direction={this.state.sort.NamedInsured}>
                                            First Name Insured
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("BrokerCompanyName")}
                                                    direction={this.state.sort.BrokerCompanyName}>
                                            Agency Name
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("AccountName")}
                                                    direction={this.state.sort.AccountName}>
                                            Contact Name
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("AccountName")}
                                                    direction={this.state.sort.AccountEmail}>
                                            Contact Email
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("CustomerNumber")}
                                                    direction={this.state.sort.CustomerNumber}>
                                            Customer Number
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("DateStarted")}
                                                    direction={this.state.sort.DateStarted}>
                                            Date Started
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("DateSubmitted")}
                                                    direction={this.state.sort.DateSubmitted}>
                                            Date Submitted
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("Version")}
                                                    direction={this.state.sort.Version}>
                                            Version
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("Status")}
                                                    direction={this.state.sort.Status}>
                                            Status
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("Transaction")}
                                                    direction={this.state.sort.Transaction}>
                                            Renewals vs New Business
                                        </TableSort>
                                    </th>
                                    <th>Review</th>
                                    <th>Change Status</th>
                                    <th>View Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applicationsContainer.state.attentionApplications.map(application=>(
                                    <AttentionApplication key={application.id}
                                                          applicationsContainer={applicationsContainer}
                                                          application={application}
                                                          viewApplication={this.viewApplication}
                                                          toggleComments={toggleComments}
                                                          toggleStatusMessageOpen={toggleStatusMessageOpen} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {(this.state.totalRecords > 1) &&
                    <div className="row">
                        <div className="col-sm-12">
                            <Pagination totalPages={this.state.totalPages}
                                        currentPage={this.state.currentPage}
                                        changePage={this.changePage} />
                        </div>
                    </div>
                }

                {this.state.viewApplicationVisible &&
                    <ReviewApplication 
                        applicationsContainer={applicationsContainer} 
                        toggleViewApplication={this.toggleViewApplication}
                        curApp={curApp}

                    />}
                   
            </div>    
        )
    }   
}

export default AttentionApplications