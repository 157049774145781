/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import ReassignUserModal from "./ReassignUserModal";

const componentStyle = css`
  td.check {
    border-top: none !important;
  }

  i {
    cursor: pointer;
    width: 20px;
    text-align: center;
  }
`;
class Application extends React.Component {
  state = {
    showReassignModal: false,
    applicationId: "",
  };

  handleCheckboxClick = (id) => (event) => {
    const { onCheckboxChange } = this.props;
    const isChecked = event.target.checked;
    onCheckboxChange(id, isChecked);
  };

  handleUnselectId = () => {
    this.setState({
      applicationId: "",
    });
  };

  handleReassignModalClose = () => {
    const { unselectApplication } = this.props;
    unselectApplication();
    this.setState({
      showReassignModal: false,
      applicationId: "",
    });
  };

  handleReassignModalShow = () => {
    this.setState({
      showReassignModal: true,
    });
  };

  handleReassign = async (selectedUserId) => {
    const { applicationsContainer, updateContactList, selectedApplicationIds } =
      this.props;

    if (selectedApplicationIds.length > 0) {
      await applicationsContainer.reassignMultipleApllicationsToUser({
        applicationIds: selectedApplicationIds,
        userId: selectedUserId,
      });
    } else {
      await applicationsContainer.reassignApllicationToUser(
        this.state.applicationId,
        selectedUserId
      );
    }
    this.handleReassignModalClose();
    await updateContactList();
  };

  render() {
    const {
      application,
      brokerCompanyId,
      brokerCompaniesContainer,
      unselectApplication,
    } = this.props;

    return (
      <>
        <ReassignUserModal
          show={this.state.showReassignModal}
          onHide={this.handleReassignModalClose}
          brokerCompanyId={brokerCompanyId}
          agencies={brokerCompaniesContainer.state.brokerCompanies}
          onPressSubmit={(selectedUserId) =>
            this.handleReassign(selectedUserId)
          }
        />
        <tr css={componentStyle}>
        <td colSpan="5" style={{ border: "none"}}></td>
          <td className="check" style={{ textAlign: "right",  border: "none" }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={this.handleCheckboxClick(application.id)}
              />
            </div>
          </td>
          <td>{application.agencyContact}</td>
          <td>{application.type}</td>
          <td>{application.status}</td>
          <td style={{ textAlign: "center" }}>
            <i
              className="fa fa-share"
              onClick={() => {
                this.setState({
                  applicationId: application.id,
                });
                this.handleReassignModalShow();
                unselectApplication();
              }}
            />
          </td>
        </tr>
      </>
    );
  }
}

export default Application;
