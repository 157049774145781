/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { formatDate } from "../../helpers/FormatDate";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";

import { getReasons } from "../../helpers/utils";
import { useEffect, useState } from "react";

const componentStyle = css`
  .highlighted {
    background-color: #fceb7e;
    padding: 5px;
  }, 
  .label {
    width: 15%;
  }
`;


export default function ReviewApplication(props) {
  const { applicationsContainer, curApp, toggleViewApplication } = props;
  const [reasonsToHighlight, setReasonsToHighlight] = useState([])

  const getAddress = (ani) => {
    const { city, state, postalCode } = ani;
    let address =
      city + state ? ", " + state : postalCode ? ", " + postalCode : "";
    return address;
  }
  useEffect(() => {
    if(curApp?.underwriterReferralReasons.length > 0) {
      
      setReasonsToHighlight(getReasons(curApp?.underwriterReferralReasons));

    }
  }, curApp)

  return (
    <Modal close={toggleViewApplication} css={componentStyle}>
      {!curApp && <Spinner color="#cc0000" />}

      {curApp && (
        <div css={componentStyle}>
          <div className="row applicationData margin-top">
            <div className="col">
              <h3
                className={`${
                  reasonsToHighlight.includes("namedInsuredInformation")
                    ? "highlighted"
                    : ""
                }`}
              >
                Named Insured Information
              </h3>
              <table className="table table-sm">
                <tbody>
                  <tr>
                    <td className="label">Customer Company:</td>
                    <td>{curApp.namedInsured.name}</td>
                  </tr>
                  <tr>
                    <td className="label">Customer Contact:</td>
                    <td>
                      {curApp.namedInsured.firstName +
                        " " +
                        curApp.namedInsured.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Phone Number:</td>
                    <td>{curApp.namedInsured.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td className="label">Company Email:</td>
                    <td>{curApp.namedInsured.email}</td>
                  </tr>
                  <tr>
                    <td className="label">Address Line 1:</td>
                    <td>{curApp.namedInsured.addressLine1}</td>
                  </tr>
                  <tr>
                    <td className="label">Address Line 2:</td>
                    <td>{curApp.namedInsured.addressLine2}</td>
                  </tr>
                  <tr>
                    <td className="label">Company City:</td>
                    <td>{curApp.namedInsured.city}</td>
                  </tr>
                  <tr>
                    <td className="label">Company State:</td>
                    <td>{curApp.namedInsured.state}</td>
                  </tr>
                  <tr>
                    <td className="label">Company Zip:</td>
                    <td>{curApp.namedInsured.postalCode}</td>
                  </tr>
                  <tr>
                    <td className="label">Customer Number:</td>
                    <td>{curApp.namedInsured.customerNumber}</td>
                  </tr>
                  <tr>
                    <td className="label">For Business:</td>
                    <td>
                      {curApp.acceptedNonIndividualPolicyDisclaimer
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Effective Date:</td>
                    <td>{formatDate(curApp.dateEffectiveUtc)}</td>
                  </tr>
                  <tr>
                    <td className="label">Expiration Date:</td>
                    <td>{formatDate(curApp.dateExpirationUtc)}</td>
                  </tr>
                  <tr>
                    <td className="label">Different than 1 year:</td>
                    <td>{curApp.isMultiYearTerm ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3
                className={`${
                  reasonsToHighlight.includes("coverages") ? "highlighted" : ""
                }`}
              >
                Coverages
              </h3>
              {curApp?.coverages?.length === 0 && <p>None</p>}
              {curApp?.coverages.length > 0 && (
                <table className="table table-sm">
                  <tbody>
                    {curApp?.coverages.map((coverage, index) => (
                      <tr key={index}>
                        <td>{coverage.name}</td>
                        <td className="label">{coverage.insuringAgreement}</td>
                        <td>
                          {coverage.field1Value === "true"
                            ? "Yes"
                            : coverage.field1Value === "false"
                            ? "No"
                            : coverage.field1Value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3>Locations</h3>
              {curApp?.locations.length === 0 && <p>None</p>}
              {curApp?.locations.length > 0 && (
                <>
                  {curApp?.locations.map((loc, index) => (
                    <table key={index} className="table table-sm">
                      <tbody>
                        <tr>
                          <td className="label">Location Number:</td>
                          <td>{loc.locationNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Site Use:</td>
                          <td>
                            {loc.siteUse}{" "}
                            {loc.otherSiteUse ? " - " + loc.otherSiteUse : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Street Address:</td>
                          <td>{loc?.address}</td>
                        </tr>
                        <tr>
                          <td className="label">City:</td>
                          <td>{loc?.city}</td>
                        </tr>
                        <tr>
                          <td className="label">State:</td>
                          <td>{loc?.state}</td>
                        </tr>
                        <tr>
                          <td className="label">Zip Code:</td>
                          <td>{loc?.postalCode}</td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3
                className={`${
                  reasonsToHighlight.includes("aboveOrUndergroundStorageTanks")
                    ? "highlighted"
                    : ""
                }`}
              >
                Aboveground Storage Tanks
              </h3>
              {curApp?.abovegroundStorageTanks.length === 0 && <p>None</p>}
              {curApp?.abovegroundStorageTanks.length > 0 && (
                <>
                  {curApp?.abovegroundStorageTanks.map((ast, index) => (
                    <table key={index} className="table table-sm">
                      <tbody>
                        <tr>
                          <td className="label">Location:</td>
                          <td>{ast?.location?.address}</td>
                        </tr>
                        <tr>
                          <td className="label">Tank Age:</td>
                          <td>{ast?.tankAgeInNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Construction:</td>
                          <td>
                            {ast?.construction}{" "}
                            {ast?.otherConstruction
                              ? " - " + ast?.otherConstruction
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Capacity:</td>
                          <td>{ast?.capacityInNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Contents:</td>
                          <td>{ast.contents}</td>
                        </tr>
                        <tr>
                          <td className="label">Leak Detection:</td>
                          <td>{ast?.leakDetection === "yes" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td className="label">Secondary Containment:</td>
                          <td>
                            {ast?.secondaryContainment === "yes" ? "Yes" : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            Retroactive Date on Existing Policy:
                          </td>
                          <td>
                            {formatDate(
                              ast?.retroactiveDateOnExistingPolicyUtc
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Deductible:</td>
                          <td>{ast?.deductible}</td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3
                className={`${
                  reasonsToHighlight.includes("aboveOrUndergroundStorageTanks")
                    ? "highlighted"
                    : ""
                }`}
              >
                Underground Storage Tanks
              </h3>
              {curApp?.undergroundStorageTanks.length === 0 && <p>None</p>}

              {curApp?.undergroundStorageTanks.length > 0 && (
                <>
                  {curApp?.undergroundStorageTanks.map((ust, index) => (
                    <table key={index} className="table table-sm">
                      <tbody>
                        <tr>
                          <td className="label">Location</td>
                          <td>{ust?.location.address}</td>
                        </tr>
                        <tr>
                          <td className="label">Tank Age</td>
                          <td>{ust?.tankAgeInNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Construction</td>
                          <td>
                            {ust?.construction}{" "}
                            {ust?.otherConstruction
                              ? " - " + ust?.otherConstruction
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Capacity</td>
                          <td>{ust?.capacityInNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Contents</td>
                          <td>
                            {ust.contents}{" "}
                            {ust?.otherContents
                              ? " - " + ust?.otherContents
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Leak Detection</td>
                          <td>{ust?.typeOfLeakDetection}</td>
                        </tr>
                        <tr>
                          <td className="label">
                            Retroactive Date on Existing Policy
                          </td>
                          <td>
                            {formatDate(
                              ust?.retroactiveDateOnExistingPolicyUtc
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Deductible</td>
                          <td>{ust?.deductible}</td>
                        </tr>
                        <tr>
                          <td className="label">
                            Tank Tightness Test Passed within last 12 months
                          </td>
                          <td>
                            {ust?.tankTightnessTestPassed === "yes"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Line Construction</td>
                          <td>{ust?.pipeLineConstruction}</td>
                        </tr>
                        <tr>
                          <td className="label">Pipe Age</td>
                          <td>{ust?.pipeAgeInNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Leak Detection:</td>
                          <td>
                            {ust?.pipeLeakDetection === "yes" ? "Yes" : "No"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3
                className={`${
                  reasonsToHighlight.includes("requirementQuestions")
                    ? "highlighted"
                    : ""
                }`}
              >
                Requirement Questions
              </h3>

              <table className="table table-sm">
                <tbody>
                  {curApp?.requirementQuestions.map((question, index) => (
                    <tr key={index}>
                      <td>
                        {index + 1}. {question?.label}{" "}
                        {question?.value === "yes" ? "Yes" : "No"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3>Endorsement Questions</h3>

              <p className="margin-top">
                Certificate for financial assurance certificate?{" "}
                {curApp.requestedCertificateForFinancialAssurance
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3>Additional Named Insured</h3>
              {curApp?.additionalNamedInsureds?.filter(
                (insured) => insured.type === "Named"
              )?.length === 0 && <p>None</p>}
              {curApp?.additionalNamedInsureds?.filter(
                (insured) => insured.type === "Named"
              )?.length > 0 && (
                <>
                  {Array.from(curApp?.additionalNamedInsureds)
                    ?.filter((insured) => insured.type === "Named")
                    .map((ani, index) => (
                      <table key={index} className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="label">
                              {ani?.name}
                              <br />
                              {ani?.addressLine1}
                              <br />
                              {ani?.addressLine2 && (
                                <>
                                  {ani?.addressLine2}
                                  <br />
                                </>
                              )}
                              {getAddress(ani)}
                            </td>
                            <td>
                              Is the proposed Additional Named Insured the owner
                              of an insured tank; the operator of an insured
                              tank; the landlord of a location where an insured
                              tank is located; or, an entity related to any of
                              the preceding groups?{" "}
                              {ani?.isTankOwner ? "Yes" : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                </>
              )}
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3>Additional Insured</h3>
              {curApp?.additionalNamedInsureds?.filter(
                (insured) => insured.type === "Additional"
              ).length === 0 && <p>None</p>}
              {curApp?.additionalNamedInsureds?.filter(
                (insured) => insured.type === "Additional"
              ).length > 0 && (
                <>
                  {Array.from(curApp?.additionalNamedInsureds)
                    ?.filter((insured) => insured.type === "Additional")
                    .map((ani, index) => (
                      <table key={index} className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="label">{ani?.name}</td>
                            <td>
                              Is the proposed Additional Insured the owner of an
                              insured tank; the operator of an insured tank; the
                              landlord of a location where an insured tank is
                              located; or, an entity related to any of the
                              preceding groups?{" "}
                              {ani?.isTankOwner ? "Yes" : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                </>
              )}
            </div>
          </div>

          <div className="row margin-top applicationData">
            <div className="col">
              <h3>Notice of cancellation to a third party entities</h3>
              {!curApp.noticeOfCancellationEntity1 &&
                !curApp.noticeOfCancellationEntity2 && (
                  <p>None</p>
                )}
              {(curApp.noticeOfCancellationEntity1 ||
                curApp.noticeOfCancellationEntity2) && (
                <>
                  <table className="table table-sm">
                    <tbody>
                      {curApp.noticeOfCancellationEntity1 && (
                        <tr>
                          <td>{curApp.noticeOfCancellationEntity1}</td>
                          <td>{curApp.noticeOfCancellationEntity1Address}</td>
                        </tr>
                      )}
                      {curApp.noticeOfCancellationEntity2 && (
                        <tr>
                          <td>{curApp.noticeOfCancellationEntity2}</td>
                          <td>{curApp.noticeOfCancellationEntity2Address}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>

          <div className="row applicationData">
            <div className="col">
              <p>Terrorism coverage? {curApp.tria ? "Yes" : "No"}</p>
            </div>
          </div>

          {(curApp?.total !== null || curApp?.total !== undefined) && (
            <div className="row margin-top applicationData">
              <div className="col">
                <h3>
                  Total:{" "}
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(curApp?.total)}
                </h3>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
