/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"
import { formatDateWithTime } from "../../helpers/FormatDate"

const componentStyle = css`
    td.check{
        border-top: none !important;
    }

    i{
        cursor: pointer;
    }
`
class User extends React.Component{


    editAccount = async ()=>{
        const { accountsContainer, user} = this.props
        await accountsContainer.getAccount(user.id)
        history.push("/gaig-users/user")
    }

    deleteUser = async (name, id)=>{
        const { accountsContainer } = this.props
        let confirm = window.confirm("Are you sure you want to delete " + name + "?")
        if(confirm){
           await accountsContainer.deleteAccounts([id])
           await accountsContainer.searchAccountAdmins()
        }
    }

    markUserForDeletion = (id, e)=>{
        const { accountsContainer } = this.props
        accountsContainer.markAccountForDeletion(id, e.currentTarget.checked)
    }


    render(){
        const { user } = this.props

        return(
            <tr css={componentStyle}>
                <td>
                    <div className="form-check">
                        <input className="form-check-input"
                               type="checkbox"
                               onChange={(e)=>{this.markUserForDeletion(user.id, e)}} />
                    </div>
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.lastLoginDateTimeUtc ? formatDateWithTime(user.lastLoginDateTimeUtc) : ""}</td>
                <td className="action">
                    <i className="fa fa-pencil-square-o" 
                       onClick={ ()=>{
                        this.editAccount(user.id)
                    }} />
                </td>
                <td className="action">
                    <i className="fa fa-trash"
                       onClick={()=>{this.deleteUser(user.name, user.id)}} />
                </td>
            </tr>
        )
    }   
}

export default User