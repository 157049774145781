/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Modal from "../../components/Modal"

const componentStyle = css`

`

class Comments extends React.Component{ 

    state={
        comment: "",
        saving: false
    }

    updateValue = (e)=>{
        e = e.currentTarget

        this.setState({
            comment: e.value
        })
    }

    postComment = async()=>{
        const { applicationsContainer } = this.props

        if(!this.state.comment){
            return
        }

        this.setState({
            saving: true
        })

        await applicationsContainer.postComment(this.state.comment)

        this.setState({
            comment: "",
            saving: false
        })
    }

    render(){
        const { applicationsContainer, toggleComments } = this.props
        return(
            <Modal close={toggleComments}>
                <div css={componentStyle}>
                    <div className="row">
                        <div className="col">
                            <h3 className="margin-bottom">Comments</h3>

                            {applicationsContainer.state.comments.map(comment=>(
                                <p key={comment.id}>
                                    <strong>{comment.from.firstName} {comment.from.lastName}</strong>: {comment.content}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="row margin-top">
                        <div className="col">
                            <textarea className="form-control form-control-sm"
                                    id="comment"
                                    name="comment"
                                    placeholder=""
                                    rows="2"
                                    value={this.state.comment}
                                    onChange={this.updateValue}>
                            </textarea>
                        </div>
                    </div>

                    <div className="row button-group">
                        <div className="col">
                            <button className="btn btn-sm btn-primary float-right"
                                    onClick={this.postComment}
                                    disabled={this.state.saving}>
                                Post Comment
                            </button>
                        </div>
                    </div>
                </div>                
            </Modal>
        )
    }   
}

export default Comments