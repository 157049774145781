/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { getRecordsPerPage } from "../../constants/Pagination"
import Registration from "./Registration"
import Pagination from "../../components/Pagination"
import TableSort from "../../components/TableSort"

const componentStyle = css`
`
class Registrations extends React.Component{ 

    state = {
        totalRecords: 0,
        recordsPerPage: getRecordsPerPage(),
        totalPages: 0,
        currentPage: 1,
        orderBy: "brokerCompanyName",
        sort: {
            RegistrationName: "asc",
            AccountName: null,
            AccountEmail: null,
            AccountDateRegistered: null
        }
    }

    componentDidMount = ()=>{        
        this.getRegistrations()
    }

    getRegistrations = async()=>{
        const { registrationsContainer } = this.props
        const totalRecords = await registrationsContainer.getRegistrations(this.state.currentPage,
                                                                           getRecordsPerPage(),
                                                                           this.state.orderBy,
                                                                           this.state.sort[this.state.orderBy] === "asc" ? true : false)
        this.setState({
            totalRecords: totalRecords,
            totalPages: Math.ceil(totalRecords/this.state.recordsPerPage)
        })
    }

    sortTable = async (orderBy, direction)=>{
        let tempSort = {}
        
        for (const key of Object.keys(this.state.sort)) {
            tempSort[key] = null;
        }
        
        if(!direction){
            tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc"
        }else{
            tempSort[orderBy] = direction
        }    
                
        await this.setState({
            orderBy: orderBy,
            sort: tempSort,
            currentPage: 1
        })

        this.getRegistrations()
    }

    changePage = async (page)=>{
        await this.setState({
            currentPage: page
        })

        this.getRegistrations()
    }

    render(){
        const { brokerCompaniesContainer, accountsContainer, registrationsContainer } = this.props

        return(
            <div css={componentStyle}>
                <div className="row margin-top">
                    <div className="col">
                        <h3>Registrations Pending Approval</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("RegistrationName")}
                                                   direction={this.state.sort.RegistrationName}>
                                            Agency Name
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("AccountName")}
                                                   direction={this.state.sort.AccountName}>
                                            Contact Name
                                        </TableSort>
                                        </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("AccountEmail")}
                                                   direction={this.state.sort.AccountEmail}>
                                            Contact Email
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("AccountDateRegistered")}
                                                   direction={this.state.sort.AccountDateRegistered}>
                                            Date Registered
                                        </TableSort>
                                        </th>
                                    <th>
                                        Approve/Deny
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {registrationsContainer.state.registrations.map((registration, index)=>(
                                    <Registration key={index}
                                                  registration={registration}
                                                  brokerCompaniesContainer={brokerCompaniesContainer}
                                                  accountsContainer={accountsContainer}
                                                  registrationsContainer={registrationsContainer} />
                                ))}
                                { (registrationsContainer.state.registrations.length === 0 &&
                                   !registrationsContainer.state.isLoading) &&
                                    <tr>
                                        <td colSpan="5">
                                            No Results
                                        </td>
                                    </tr>
                                }                                
                            </tbody>
                        </table>
                    </div>
                </div>
                {(this.state.totalRecords > 1) &&
                    <div className="row">
                        <div className="col-sm-12">
                            <Pagination totalPages={this.state.totalPages}
                                        currentPage={this.state.currentPage}
                                        changePage={this.changePage} />
                        </div>
                    </div>
                }
                
            </div>
        )
    }   
}

export default Registrations