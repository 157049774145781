/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import AttentionApplications from "./AttentionApplications"
import AgencyApplications from "./AgencyApplications"
import BehalfOfApplications from "./BehalfOfApplications"
import Policies from "./Policies"
import Comments from "./Comments"
import StatusMessage from "./StatusMessage"

const componentStyle = css`
    margin-top: 15px;

    .row-flex{
        display: flex;
        margin-top: 15px;
    }

    .row-flex .col{
        flex: 1;
    }

    h3.remainingPolicies{
        color: #cc0000;
        font-size: 16px;
        margin-bottom: 20px;
    }

    button.application{
        background-color: #063769;
        color: #fff;
        margin-top: 22px;
    }
`
class Home extends React.Component{

    state = {
        commentsOpen: false,
        statusMessageOpen: false
    }

    
    toggleComments = ()=>{
        this.setState({
            commentsOpen: this.state.commentsOpen ? false : true
        })
    }

    toggleStatusMessageOpen = ()=>{
        this.setState({
            statusMessageOpen: this.state.statusMessageOpen ? false : true
        })
    }

    componentDidMount = async ()=>{
        const { applicationsContainer, policyNumbersContainer } = this.props
        await policyNumbersContainer.getPolicyNumbersRemaining()
        await applicationsContainer.getApplicationStatuses()
    }

    render(){
        const { applicationsContainer, policyNumbersContainer } = this.props

        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <div className="float-left">
                            <h2>Welcome Admin!</h2>
                            <h3 className="remainingPolicies">{policyNumbersContainer.state.policyNumbersRemaining} Policy Numbers Remaining</h3>
                        </div>                        
                       
                        <button className="btn btn-sm application float-right"
                                onClick={applicationsContainer.newApplication}>
                            <i className="fa fa-file-text-o" aria-hidden="true"/> Create Application
                        </button>
                           
                    </div>
                </div>

                {(applicationsContainer.state.applicationsStatuses.length > 1) &&
                    <>
                    <div className="row">
                        <div className="col">
                            <AttentionApplications applicationsContainer={applicationsContainer}
                                                   toggleComments={this.toggleComments}
                                                   toggleStatusMessageOpen={this.toggleStatusMessageOpen} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <AgencyApplications applicationsContainer={applicationsContainer}
                                                toggleComments={this.toggleComments} />
                        </div>
                    </div>
                    </>                
                }                

                <div className="row">
                    <div className="col">
                        <BehalfOfApplications applicationsContainer={applicationsContainer}
                                              toggleComments={this.toggleComments} />
                    </div>
                </div>
                
                <div className="row">
                    <div className="col">
                        <Policies applicationsContainer={applicationsContainer} />
                    </div>
                </div>

                {this.state.commentsOpen &&
                    <Comments applicationsContainer={applicationsContainer}
                              toggleComments={this.toggleComments} />
                }

                {this.state.statusMessageOpen &&
                    <StatusMessage applicationsContainer={applicationsContainer}
                                   toggleStatusMessageOpen={this.toggleStatusMessageOpen} />
                }
    
            </div>
        )
    }   
}

export default Home