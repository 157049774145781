import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: "https://58144d73a1d54edc95a36ccb21f36aa1@o1127737.ingest.sentry.io/6194446",
    integrations: [new BrowserTracing()],
    beforeSend(event) {
        const url = event.request?.url;

        if (url?.includes("tankassureadmin.saleselement.net")) {
          return event;
        }

        return null;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

ReactDOM.render(
    <App />, //not using strict mode because of react-sortable-hoc... 
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
