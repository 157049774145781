/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { testValidEmail } from "../../helpers/ValidateEmail";
import history from "../../components/History";
import { poBoxRegex } from "../../helpers/utils";
import WarningModal from "../../components/WarningModal";

const componentStyle = css`
  h3 {
    margin-bottom: 15px;
  }

  .form {
    margin-top: 15px;
  }
`;
class Application1 extends React.Component {
  state = {
    name: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    dateEffectiveUtc: "",
    dateExpirationUtc: "",
    isMultiYearTerm: false,
    acceptedNonIndividualPolicyDisclaimer: false,
    contacts: [],
    contactsMap: new Map(),
    gaiUserId: "",
    agencyId: "",
    admittedProducerCode: "",
    agencyContactEmailAddress: "",
    isValidating: false,
    showWarning: false,
    modalDisplayed: false,
    error: "",
    warningMessage: "",
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const {
      applicationsContainer,
      brokerCompaniesContainer,
      accountsContainer,
    } = this.props;
    await brokerCompaniesContainer.getBrokerCompaniesList();

    if (!accountsContainer.state?.editAccount?.email) {
      await accountsContainer.getCurrentAccount();
    }

    const ca = applicationsContainer.state.currentApplication;

    if (ca) {
      const ni = ca.namedInsured;

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      let dateEffectiveUtc = new Date(ca.dateEffectiveUtc);
      dateEffectiveUtc.setHours(0);
      dateEffectiveUtc.setMinutes(0);
      dateEffectiveUtc.setSeconds(1);

      if (ca.submittedFor) {
        await this.getContacts(ca.submittedFor.brokerCompanyId);
      }

      await this.setState({
        name: ni.name,
        firstName: ni.firstName,
        lastName: ni.lastName,
        phoneNumber: ni.phoneNumber,
        email: ni.email,
        addressLine1: ni.addressLine1,
        addressLine2: ni.addressLine2,
        city: ni.city,
        state: ni.state,
        postalCode: ni.postalCode,
        dateEffectiveUtc:
          dateEffectiveUtc > today ? new Date(ca.dateEffectiveUtc) : "",
        dateExpirationUtc:
          dateEffectiveUtc > today ? new Date(ca.dateExpirationUtc) : "",
        isMultiYearTerm: ca.isMultiYearTerm,
        gaiUserId: ca.submittedFor ? ca.submittedFor.id : "",
        agencyContactEmailAddress: ca.submittedFor ? ca.submittedFor.email : "",
        admittedProducerCode: ca.submittedFor
          ? ca.submittedFor.admittedProducerCode
          : "",
        agencyId: ca.submittedFor ? ca.submittedFor.brokerCompanyId : "",
        acceptedNonIndividualPolicyDisclaimer:
          ca.acceptedNonIndividualPolicyDisclaimer,
        isValidating: false,
        error: false,
      });
    }
  };

  updateValue = async (e) => {
    e = e.currentTarget;
    let value = e.value;

    if (e.name === "acceptedNonIndividualPolicyDisclaimer") {
      value = e.checked ? true : false;
    } else if (e.name === "isMultiYearTerm") {
      value = e.checked ? true : false;
    }

    await this.setState({
      [e.name]: value,
    });

    if (e.name === "gaiUserId") {
      this.setState({
        admittedProducerCode: value
          ? this.state.contactsMap.get(value).admittedProducerCode
          : "",
        agencyContactEmailAddress: value
          ? this.state.contactsMap.get(value).email
          : "",
      });
    }

    if (this.state.isValidating) {
      this.validateForm();
    }
  };

  setDate = async (date, name) => {
    await this.setState({
      [name]: date,
    });

    if (name === "dateEffectiveUtc") {
      const expirationDate = new Date(date);

      if (expirationDate.getMonth() === 1 && expirationDate.getDate() === 29) {
        expirationDate.setDate(expirationDate.getDate() - 1);
      }

      expirationDate.setFullYear(expirationDate.getFullYear() + 1);

      this.setState({
        dateExpirationUtc: expirationDate,
      });
    }

    if (this.state.isValidating) {
      this.validateForm();
    }
  };

  getContacts = async (e) => {
    e = e.currentTarget ? e.currentTarget : e;

    if (!e.value && !e) {
      await this.setState({
        contacts: [],
        contactsMap: new Map(),
        agencyId: "",
      });
      return;
    }

    await this.setState({
      agencyId: e.value ? e.value : e,
      gaiUserId: "",
      admittedProducerCode: "",
      agencyContactEmailAddress: "",
    });

    const { brokerCompaniesContainer } = this.props;
    const result = await brokerCompaniesContainer.getBrokerCompany(
      e.value ? e.value : e,
      false
    );

    const contactsMap = new Map();
    result.users.map((user) => {
      contactsMap.set(user.id, user);
      return true;
    });

    await this.setState({
      contacts: result.users,
      contactsMap: contactsMap,
    });

    if (this.state.isValidating) {
      this.validateForm();
    }
  };

  submit = async () => {
    if (!(await this.validateForm())) {
      return;
    }

    const { applicationsContainer } = this.props;
    const result = await applicationsContainer.submitApplication(this.state);

    if (!result.succeeded) {
      this.setState({
        error: result.errorMessage,
      });
    } else {
      history.push("/application/2");
    }
  };

  validateForm = async () => {
    this.setState({
      isValidating: true,
    });

    const notRequired = [
      "addressLine2",
      "isMultiYearTerm",
      "contacts",
      "contactsMap",
      "admittedProducerCode",
      "agencyContactEmailAddress",
      "error",
      "isValidating",
      "showWarning",
      "modalDisplayed",
      "warningMessage" 
    ];

    let valid = true;
    let error = "Please complete all required fields";

    for (const [key, value] of Object.entries(this.state)) {
      if (!notRequired.includes(key)) {
        if (!value) {
          valid = false;
          break;
        }
      }
    }

    if (valid) {
      const selectedContact = this.state.contacts.find(
        (contact) => contact.id === this.state.gaiUserId
      );

      if (this.state.state === "NY" && !selectedContact?.nyftzProducerCode) {
        valid = false;
        this.handleShowNYFTZWarningModal();
      }
    }

    if (valid) {
      if (!testValidEmail(this.state.email)) {
        valid = false;
        error = "Please enter a valid email address";
      }
    }

    if (valid) {
      if (poBoxRegex.test(this.state.addressLine1?.trim())) {
        valid = false;
        error = "P.O. Box addresses are not allowed.";
      }
    }

    if (valid && this.state.addressLine2) {
      if (poBoxRegex.test(this.state.addressLine2?.trim())) {
        valid = false;
        error = "P.O. Box addresses are not allowed.";
      }
    }

    if (!valid) {
      this.setState({
        error: error,
      });
      return false;
    } else {
      this.setState({
        error: "",
      });
      return true;
    }
  };

  handleShowNYFTZWarningModal = () => {
    this.setState({
      warningMessage:
        "This contact does not have a NYFTZ Producer Code, please add one.",
      showWarning: true,
    });
  };

  handleShowWarning = () => {
    if (!this.modalDisplayed) {
      this.setState({
        warningMessage:
          "Only one First Named Insured is allowed. You may add others further next in the application.",
        showWarning: true,
        modalDisplayed: true,
      });
    }
  };

  handleFocus = (e) => {
    if (!this.state.modalDisplayed) {
      e.target.blur();
      this.handleShowWarning();
    }
  };

  handleCloseWarning = () => {
    this.setState({
      showWarning: false,
    });
  };

  render() {
    const { applicationsContainer, brokerCompaniesContainer } = this.props;

    return (
      <div css={componentStyle}>
        <WarningModal
          show={this.state.showWarning}
          handleClose={this.handleCloseWarning}
          message={this.state.warningMessage}
        />
        <div className="row">
          <div className="col">
            <h2>Application for Storage Tank Policy Page 1</h2>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="directions">
              <strong>Directions:</strong>
            </p>
            <ul>
              <li>
                This form must be dated and signed by the authorized agent.
              </li>
              <li>Answer all required questions completely.</li>
              <li>
                Please upload any supporting documentation where necessary
                (location or storage tank spreadsheets, etc.). Supporting
                information is considered part of this application and is
                subject to same terms and conditions.
              </li>
            </ul>
            <p>
              Items with the red asterisk (
              <span className="required-example">*</span>) must be completed in
              order to proceed
            </p>
          </div>
        </div>

        <div className="row form">
          <div className="col-sm-6">
            <h3>Named Insured Information</h3>

            <div className="form-group">
              <label
                htmlFor="name"
                className={
                  this.state.isValidating && !this.state.name ? "required" : ""
                }
              >
                Customer Company:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="name"
                name="name"
                value={this.state.name}
                onChange={this.updateValue}
                required
                onFocus={this.handleFocus}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="name"
                className={
                  this.state.isValidating && !this.state.firstName
                    ? "required"
                    : ""
                }
              >
                Customer First Name:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="firstName"
                name="firstName"
                value={this.state.firstName}
                onChange={this.updateValue}
                required
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="lastName"
                className={
                  this.state.isValidating && !this.state.lastName
                    ? "required"
                    : ""
                }
              >
                Customer Last Name:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="lastName"
                name="lastName"
                value={this.state.lastName}
                onChange={this.updateValue}
                required
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="phoneNumber"
                className={
                  this.state.isValidating && !this.state.phoneNumber
                    ? "required"
                    : ""
                }
              >
                Phone Number:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="phoneNumber"
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.updateValue}
                required
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="email"
                className={
                  this.state.isValidating &&
                  (!this.state.email || !testValidEmail(this.state.email))
                    ? "required"
                    : ""
                }
              >
                Company Email:<span className="required">*</span>
              </label>
              <input
                type="email"
                className="form-control form-control-sm"
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.updateValue}
                required
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="addressLine1"
                className={
                  this.state.isValidating &&
                  (!this.state.addressLine1 ||
                    poBoxRegex.test(this.state.addressLine1?.trim()))
                    ? "required"
                    : ""
                }
              >
                Address Line 1:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="addressLine1"
                name="addressLine1"
                value={this.state.addressLine1}
                onChange={this.updateValue}
                required
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="addressLine2"
                className={
                  this.state.isValidating &&
                  this.state.addressLine2 &&
                  poBoxRegex.test(this.state.addressLine2?.trim())
                    ? "required"
                    : ""
                }
              >
                Company Address Line 2:
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="addressLine2"
                name="addressLine2"
                value={this.state.addressLine2}
                onChange={this.updateValue}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="city"
                className={
                  this.state.isValidating && !this.state.city ? "required" : ""
                }
              >
                Company City:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="city"
                name="city"
                value={this.state.city}
                onChange={this.updateValue}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="state"
                className={
                  this.state.isValidating && !this.state.state ? "required" : ""
                }
              >
                Company State:<span className="required">*</span>
              </label>
              <select
                className="form-control form-control-sm"
                id="state"
                name="state"
                value={this.state.state}
                onChange={this.updateValue}
              >
                <option value="">--- Select Company State ---</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="VI">Virgin Islands</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="form-group">
              <label
                htmlFor="name"
                className={
                  this.state.isValidating && !this.state.postalCode
                    ? "required"
                    : ""
                }
              >
                Company Zip:<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="postalCode"
                name="postalCode"
                value={this.state.postalCode}
                onChange={this.updateValue}
                required
              />
            </div>
            <div className="form-check">
              <input
                className="form-check-input required"
                type="checkbox"
                id="acceptedNonIndividualPolicyDisclaimer"
                name="acceptedNonIndividualPolicyDisclaimer"
                checked={
                  this.state.acceptedNonIndividualPolicyDisclaimer
                    ? true
                    : false
                }
                onChange={this.updateValue}
              />
              <label
                htmlFor="acceptedNonIndividualPolicyDisclaimer"
                className={
                  this.state.isValidating &&
                  !this.state.acceptedNonIndividualPolicyDisclaimer
                    ? "form-check-label required"
                    : "form-check-label"
                }
              >
                This system only issues policies for businesses. TankAssure does
                not provide policies for individuals. I acknowledge that this
                application is for a business not an individual.
                <span className="required">*</span>
              </label>
            </div>
          </div>

          <div className="col-sm-6">
            <h3>Submission Information</h3>
            <p>Effective date may not be prior to today's date.</p>
            <div className="form-group">
              <label
                htmlFor="name"
                className={
                  this.state.isValidating && !this.state.dateEffectiveUtc
                    ? "required"
                    : ""
                }
              >
                Effective Date:<span className="required">*</span>
              </label>
              <DatePicker
                popperPlacement="bottom-end"
                minDate={new Date()}
                dateFormat="MM/dd/yyyy"
                className="form-control form-control-sm"
                id="dateEffectiveUtc"
                name="dateEffectiveUtc"
                selected={this.state.dateEffectiveUtc}
                onChange={(date) => {
                  this.setDate(date, "dateEffectiveUtc");
                }}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="name"
                className={
                  this.state.isValidating && !this.state.dateExpirationUtc
                    ? "required"
                    : ""
                }
              >
                Expiration Date:<span className="required">*</span>
              </label>
              <DatePicker
                popperPlacement="bottom-end"
                dateFormat="MM/dd/yyyy"
                className="form-control form-control-sm"
                id="dateExpirationUtc"
                name="dateExpirationUtc"
                selected={this.state.dateExpirationUtc}
                onChange={(date) => {
                  this.setDate(date, "dateExpirationUtc");
                }}
                disabled={true}
              />
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="isMultiYearTerm"
                name="isMultiYearTerm"
                checked={this.state.isMultiYearTerm ? true : false}
                onChange={this.updateValue}
              />
              <label htmlFor="isMultiYearTerm" className="form-check-label">
                If you require a different policy term than a 1 year term,
                please check here.
              </label>
            </div>
            <div className="form-group margin-top">
              <label
                htmlFor="agencyId"
                className={
                  this.state.isValidating && !this.state.agencyId
                    ? "required"
                    : ""
                }
              >
                Agency Name:<span className="required">*</span>
              </label>
              <select
                className="form-control form-control-sm"
                id="agencyId"
                name="agencyId"
                value={this.state.agencyId}
                onChange={this.getContacts}
              >
                <option value="">--- Select ---</option>
                {brokerCompaniesContainer.state.brokerCompanies.map(
                  (brokerCompany) => (
                    <option key={brokerCompany.id} value={brokerCompany.id}>
                      {brokerCompany.name}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="form-group margin-top">
              <label
                htmlFor="gaiUserId"
                className={
                  this.state.isValidating && !this.state.gaiUserId
                    ? "required"
                    : ""
                }
              >
                Agency Contact Name:<span className="required">*</span>
              </label>
              <select
                className="form-control form-control-sm"
                id="gaiUserId"
                name="gaiUserId"
                value={this.state.gaiUserId}
                onChange={this.updateValue}
              >
                <option value="">--- Select ---</option>
                {this.state.contacts.map((contact) => (
                  <option key={contact.id} value={contact.id}>
                    {contact.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="admittedProducerCode">
                Admitted Producer Code:
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="admittedProducerCode"
                name="admittedProducerCode"
                value={
                  this.state.admittedProducerCode
                    ? this.state.admittedProducerCode
                    : ""
                }
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="agencyContactEmailAddress">
                Agency Contact Email Address:
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="agencyContactEmailAddress"
                name="agencyContactEmailAddress"
                value={this.state.agencyContactEmailAddress}
                disabled={true}
              />
            </div>

            {this.state.gaiUserId && !this.state.admittedProducerCode && (
              <p className="error producerError">
                This agency contact has no producer code. You must enter a
                producer code into the agency contact's file before you can
                proceed to make an application for them.
              </p>
            )}
          </div>
        </div>

        {(this.state.error !== "" || applicationsContainer.state.error) && (
          <div className="row">
            <div className="col">
              <p className="error">
                {this.state.error
                  ? this.state.error
                  : applicationsContainer.state.error}
              </p>
            </div>
          </div>
        )}

        <div className="row button-group">
          <div className="col">
            <button
              className="btn btn-sm btn-primary float-right"
              onClick={this.submit}
              disabled={applicationsContainer.state.isLoading}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Application1;
