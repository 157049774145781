import { sendGet, sendPost } from "./generic";

export const postReportingData = (payload) => {
  return sendPost("Reporting", payload);
};

export const exportReportingData = (payload) => {
  return sendPost("Reporting/Export", payload);
};

export const getReportingTypes = () => {
  return sendGet("Reporting/ReportingTypes");
};

export const getApplicationsStatus = () => {
  return sendGet("Applications/Status");
};
