/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { formatDate } from "../../helpers/FormatDate"

const componentStyle = css`
`
class Registration extends React.Component{

    edit = async()=>{
        const { registration, registrationsContainer } = this.props
        registrationsContainer.editRegistration(registration.agencyId, registration.companyRegistrationId)
    }

    render(){
        const { registration } = this.props

        return(
            <tr css={componentStyle}>
                <td>{registration.agencyName}</td>
                <td>{registration.contactName}</td>
                <td>{registration.contactEmail}</td>
                <td>{formatDate(registration.dateRegisteredUtc)}</td>
                <td>
                    <button className="anchor"
                            onClick={this.edit}>
                        Approve Deny
                    </button>
                </td>
            </tr>
            
        )
    }   
}

export default Registration