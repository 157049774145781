/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { register } from "../../api"
import history from "../../components/History"
import { testValidEmail } from "../../helpers/ValidateEmail"

const componentStyle = css`

    h3{
        margin-top: 15px;
        margin-bottom: 15px;
    }

    button.margin-top{
        margin-top: 24px;
    }
`
class EditRegistration extends React.Component{ 

    state = {
        companyRegistrationId: "",
        agencyId: "",
        name: "",
        typeOfEntity: 0,
        producerCode: "",
        admittedProducerCode: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        mainPhone: "",
        website: "",
        userId: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        approve: "",
        error: "",
        newAgency: false,
        agencyInformation: true,
        contactInformation: false
    }

    componentDidMount(){
        const { registrationsContainer } = this.props
        const registration = registrationsContainer.state.registration 
        
        if(!registration){
            history.push("/agencies-and-contacts")
            return
        }

        this.setState({
            companyRegistrationId: registration.companyRegistrationId,
            agencyId: registration.agencyId,
            name: registration.agencyName,
            typeOfEntity: registration.typeOfEntity,
            producerCode: registration.admittedProducerCode ? "yes" : "no",
            admittedProducerCode: registration.admittedProducerCode,
            addressLine1: registration.addressLine1,
            addressLine2: registration.addressLine2,
            city: registration.city,
            state: registration.state,
            postalCode: registration.postalCode,
            mainPhone: registration.mainPhone,
            website: registration.website,
            userId: registration.userId,
            firstName: registration.firstName,
            lastName: registration.lastName,
            phoneNumber: registration.phoneNumber,
            email: registration.email,
            commission: 20,
            approve: register.approve ? register.approve : "",
            validating: false
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget

        let value = e.value

        if(e.name === "approve"){
            value = (value === "") ? "" : (value === "false") ? false : true
        }
        await this.setState({
            [e.name]: value
        })

        if(e.name === "agencyId"){
            if(value === "new"){
                this.setState({
                    newAgency: true
                })
            }else{
                this.setState({
                    newAgency: false,
                    agencyId: e.value
                })
            }
        }

        if(this.state.newAgency && this.state.validating){
            this.validateNewAgencyForm()
        }
        if(this.state.contactInformation && this.state.validating){
            this.validateContactForm()
        }

    }

    updateAgency = async ()=>{
        if(this.state.newAgency){
            if(!await this.validateNewAgencyForm()){
                return
            }
            
            const { brokerCompaniesContainer } = this.props
            
            const result = await brokerCompaniesContainer.addBrokerCompany(this.state)

            if(!result?.succeeded){
                this.setState({
                    error: result.errorMessage || "Error",
                })
            }else{
                this.setState({
                    agencyId: result.id,
                    agencyInformation: false,
                    contactInformation: true,
                    validating: false,
                    newAgency: false
                })
            }
        }else{
            this.setState({
                agencyInformation: false,
                contactInformation: true,
                validating: false,
                newAgency: false
            })
        }
    }

    validateNewAgencyForm = async ()=>{

        this.setState({
            validating: true
        })

        const notRequired = [
            "typeOfEntity",
            "admittedProducerCode",
            "addressLine2",
            "userId",
            "firstName",
            "lastName",
            "phoneNumber",
            "email",
            "approve",
            "error",
            "newAgency",
            "agencyInformation",
            "contactInformation",
            "validating"
        ]

        let valid = true
        let error = "Please complete all required fields"

        for (const [key, value] of Object.entries(this.state)) {
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }

        if(this.state.producerCode === "yes" && !this.state.admittedProducerCode){
            valid = false
        }

        if(this.state.typeOfEntity === ""){
            valid = false
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }        
    }

    updateAccount = async ()=>{
        if(!await this.validateContactForm()){
            return
        }
        const { accountsContainer } = this.props
            
        const result = await accountsContainer.approveAccount(this.state.userId, 
                                                              this.state.email, 
                                                              this.state.approve, 
                                                              this.state.companyRegistrationId)

        if(!result?.succeeded){
            this.setState({
                error: result?.errorMessage || "Error",
            })
            return false
        }
        
        const result2 = await accountsContainer.updateAccount(this.state)

        if(!result2?.succeeded){
            this.setState({
                error: result2?.errorMessage || "Error",
            })
            return false
        }

        history.push("/agencies-and-contacts")
    }

    validateContactForm = async ()=>{
        this.setState({
            validating: true
        })

        const notRequired = [
            "isLoading",
            "companyRegistrationId",
            "agencyId",
            "name",
            "typeOfEntity",
            "producerCode",
            "admittedProducerCode",
            "addressLine1",
            "addressLine2",
            "city",
            "state",
            "postalCode",
            "mainPhone",
            "website",
            "error",
            "newAgency",
            "agencyInformation",
            "contactInformation",
            "validating",
            "approve",
        ]

        let valid = true
        let error = "Please complete required all fields"

        for (const [key, value] of Object.entries(this.state)) {
            
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }

        if(this.state.approve === ""){
            valid = false
        }

        if(valid){
            if(!testValidEmail(this.state.email)){
                valid = false
                error = "Please enter a valid email address"
            }
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }      
    }
            
    render(){
        const { brokerCompaniesContainer, registrationsContainer } = this.props
        return(
    
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h2>Approve or Deny Registration</h2>
                        <p className="directions">
                            <strong>Directions</strong>: Review the registration information provided. You may edit any information as needed. Click approve to invite the user and add them to the system, or deny to decline the registration.
                        </p>
                    </div>
                </div>
                {this.state.agencyInformation &&
                    <div className="row margin-top">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="name"
                                    className={(this.state.validating && !this.state.name) 
                                        ? "required" : ""}>
                                    Agency Name:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="name" 
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.updateValue}
                                    required
                                    disabled={!this.state.newAgency} />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="agencyId">Select Agency to add to this user:</label>
                                <select className="form-control form-control-sm" 
                                        id="agencyId"
                                        name="agencyId"
                                        value={this.state.agencyId}
                                        onChange={this.updateValue}>                            
                                    <option value="">--- Select ---</option>
                                    <option value="new">NEW</option>
                                    {brokerCompaniesContainer.state.brokerCompanies.map(brokerCompany =>(
                                        <option key={brokerCompany.id}
                                                value={brokerCompany.id}>
                                            {brokerCompany.name}
                                        </option>
                                    ))}                                
                                </select>
                            </div>
                        </div>
                        {(!this.state.newAgency && this.state.agencyId) &&
                            <div className="col-sm-4">
                                <button className="btn btn-sm btn-primary margin-top"
                                        onClick={this.updateAgency}
                                        disabled={registrationsContainer.isLoading}>
                                            Submit
                                </button>
                            </div>
                        }                    
                    </div>
                }
                
                <div className="row">
                    {this.state.agencyInformation &&
                        <div className="col-sm-6">
                            <h3>Agency Information:</h3>
                            
                            <div className="form-group">
                                <label htmlFor="typeOfEntity"
                                    className={(this.state.validating && this.state.typeOfEntity === "") 
                                                ? "required" : ""}>
                                    Type of Entity:<span className="required">*</span>
                                </label>
                                <select className="form-control form-control-sm" 
                                        id="typeOfEntity"
                                        name="typeOfEntity"
                                        value={this.state.typeOfEntity}
                                        onChange={this.updateValue}
                                        required
                                        disabled={!this.state.newAgency}>                            
                                    <option value="">--- Select ---</option>
                                    {brokerCompaniesContainer.state.entityTypes.map(entityType =>(
                                        <option key={entityType.id}
                                                value={entityType.id}>
                                            {entityType.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group" css={componentStyle}>
                                <label className={(this.state.validating && !this.state.producerCode) 
                                               ? "required" : ""}>
                                    Were you provided a GAIG Admitted Producer Code?<span className="required">*</span>
                                </label>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" 
                                        type="radio" 
                                        id="producerCodeYes"
                                        name="producerCode"                                
                                        value="yes"
                                        checked={this.state.producerCode === "yes"}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                                    <label className="form-check-label"
                                        htmlFor="producerCodeYes">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" 
                                        type="radio" 
                                        id="producerCodeNo"
                                        name="producerCode"                                
                                        value="no"
                                        checked={this.state.producerCode === "no"}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                                    <label className="form-check-label"
                                        htmlFor="producerCodeNo">
                                        No
                                    </label>
                                </div>
                                <small className="form-text text-muted">If yes, please enter it below:</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="admittedProducerCode"
                                    className={(this.state.validating && 
                                                !this.state.admittedProducerCode &&
                                                this.state.producerCode === "yes") 
                                    ? "required" : ""}>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="admittedProducerCode" 
                                        name="admittedProducerCode"
                                        value={this.state.admittedProducerCode}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="addressLine1"
                                    className={(this.state.validating && !this.state.addressLine1) 
                                        ? "required" : ""}>
                                    Company Address Line 1:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="addressLine1" 
                                        name="addressLine1"
                                        value={this.state.addressLine1}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="addressLine2">Company Address Line 2:</label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="addressLine2" 
                                        name="addressLine2"
                                        value={this.state.addressLine2}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="city"
                                    className={(this.state.validating && !this.state.city) 
                                        ? "required" : ""}>
                                    Company City:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="city" 
                                        name="city"
                                        value={this.state.city}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="state"
                                    className={(this.state.validating && !this.state.state) 
                                    ? "required" : ""}>
                                    Company State:<span className="required">*</span>
                                </label>
                                <select className="form-control form-control-sm" 
                                        id="state"
                                        name="state"
                                        value={this.state.state}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency}>                            
                                    <option value="">--- Select Company State ---</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="VI">Virgin Islands</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="postalCode"
                                    className={(this.state.validating && !this.state.postalCode) 
                                    ? "required" : ""}>
                                    Postal Code:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="postalCode" 
                                        name="postalCode"
                                        value={this.state.postalCode}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mainPhone"
                                    className={(this.state.validating && !this.state.mainPhone) 
                                        ? "required" : ""}>
                                    Main Company Phone Number:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="mainPhone" 
                                        name="mainPhone"
                                        value={this.state.mainPhone}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="website"
                                    className={(this.state.validating && !this.state.website) 
                                        ? "required" : ""}>
                                    Company Web Address:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="website" 
                                        name="website"
                                        value={this.state.website}
                                        onChange={this.updateValue}
                                        disabled={!this.state.newAgency} />
                            </div>
                            
                        </div>
                    }
                
                    {this.state.contactInformation &&
                        <div className="col-sm-6">
                            <h3>Contact Information:</h3>
                                
                            <div className="form-group">
                                <label htmlFor="firstName"
                                    className={(this.state.validating && !this.state.firstName) 
                                        ? "required" : ""}>First Name:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="firstName" 
                                        name="firstName"
                                        value={this.state.firstName}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName"
                                    className={(this.state.validating && !this.state.lastName) 
                                    ? "required" : ""}>
                                    Last Name:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="lastName" 
                                        name="lastName"
                                        value={this.state.lastName}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber"
                                    className={(this.state.validating && !this.state.phoneNumber) 
                                        ? "required" : ""}>
                                    Mobile Phone Number:<span className="required">*</span>
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="phoneNumber" 
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"
                                    className={(this.state.validating && (!this.state.email || !testValidEmail(this.state.email))) 
                                        ? "required" : ""}>
                                        Email Address (Username):<span className="required">*</span>
                                </label>
                                <input type="email" 
                                        className="form-control form-control-sm" 
                                        id="email" 
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.updateValue}
                                        required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="approve"
                                    className={(this.state.validating && !this.state.approve) 
                                        ? "required" : ""}>
                                    Registration Status:<span className="required">*</span>
                                </label>
                                <select className="form-control form-control-sm" 
                                        id="approve"
                                        name="approve"
                                        value={this.state.approve}
                                        onChange={this.updateValue}>                            
                                    <option value="">--- Select ---</option>
                                    <option value="true">Approve, Invite, and add user to Agencies and Contacts</option>
                                    <option value="false">Deny registration and send denial email.</option>
                                </select>
                            </div>
                        </div>
                    }
                </div>
                
                {this.state.error !== "" &&
                    <div className="row">
                        <div className="col-sm-6">
                            <p className="error">{this.state.error}</p>
                        </div>
                    </div>
                }

                {this.state.newAgency &&
                    <div className="row button-group">
                        <div className="col-sm-6">
                            <button className="btn btn-sm btn-primary float-right"
                                    onClick={this.updateAgency}
                                    disabled={brokerCompaniesContainer.isLoading}>
                                Add Agency
                            </button>
                        </div>
                    </div>
                }

                {this.state.contactInformation &&
                    <div className="row button-group">
                        <div className="col-sm-6">
                            <button className="btn btn-sm btn-primary float-right"
                                    onClick={this.updateAccount}
                                    disabled={brokerCompaniesContainer.isLoading}>
                                        Submit
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }   
}

export default EditRegistration