/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { css } from "@emotion/react/macro"

const componentStyle = css`
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.7);

    .module {
        width: 80%;
        margin-left: 10%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        border-radius: 10px;
        padding: 30px;
        text-align: left;
    }

    .content{
        margin-top: 15px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
    }

    .content p,
    .content li {
        color: #cc0000;
        font-size: 14px;
    }

    button.close{
        position: absolute;
        top: 15px;
        right: 15px;
        color: #cc0000;
        opacity: 1;
    }
`

class Warning extends Component {

    render() {
        const { warnings, warningHeader, warningFooter, close } = this.props

        return(
            <div css={componentStyle}>    
                <div className="module">
                    <button className="float-right close"
                        onClick={close}>
                            <i className="fa fa-times" aria-hidden="true"/>
                    </button>
                    <div className="content">
                        {warningHeader &&
                            <p>{warningHeader}</p>
                        }
                        {Array.isArray(warnings) && warnings?.length > 1 &&
                            <ul>
                                {warnings?.map((warning, index)=>(
                                    <li key={index}>
                                        {warning}
                                    </li>
                                ))}
                            </ul>
                        }
                        {warnings.length === 1 &&
                            <p>{warnings[0]}</p>
                        }
                        {warningFooter &&
                            <p>{warningFooter}</p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Warning
