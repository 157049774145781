import { sendPost, sendGet, sendDelete, uploadFile } from "./generic"

export const getTotal = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/Total")
}

export const getApplicationStatuses = ()=> {
    return sendGet("Applications/Status")
}

export const getActionableStatuses = ()=> {
    return sendGet("Applications/ActionableStatus")
}

export const getApplications = (page, numberOfRecords, orderBy, descending, showAll)=>{
    return sendGet("Applications/"+ page + "/" + numberOfRecords + "/" + orderBy + "/" + descending + "/" + showAll)
}

export const getAdminApplications = (page, numberOfRecords, orderBy, descending, payload)=>{
    return sendPost("Applications/Admins/"+ page + "/" + numberOfRecords + "/" + orderBy + "/" + descending, payload)
}

export const getBrokerCompanyApplications = (searchTerm, page, numberOfRecords, orderBy, descending, payload)=>{
    return sendGet("Applications/Admins/" + searchTerm + "/" + page + "/" + numberOfRecords + "/" + orderBy + "/" + descending, payload)
}

export const getApplication = (id)=>{
    return sendGet("Applications/"+ id)
}

export const getApplicationPDF = (id)=>{
    return sendGet("Applications/"+ id + "/Pdf")
}

export const getComments = (id)=>{
    return sendGet("Applications/"+ id + "/ThreadedComments")
}

export const postComment = (applicationId, payload)=>{
    return sendPost("Applications/"+applicationId+"/ThreadedComments", payload)
}

export const submitApplication = (payload)=> {
    return sendPost("Applications", payload)
}

export const updateApplication = (applicationId, payload)=>{
    return sendPost("Applications/"+applicationId, payload)
}

export const updateApplicationStatus = (applicationId, payload)=>{
    return sendPost("Applications/"+applicationId+"/Status", payload)
}

export const getBindingQuestions = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/BindingQuestions")
}

export const getSelectedNamedInsureds = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/SelectedNamedInsureds")
}

export const setSelectedNamedInsured = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/SelectedNamedInsureds", payload)
}

export const validateNamedInsureds = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/SelectedNamedInsureds/Validate")
}

export const validateBindingQuestions = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/BindingQuestions/Validate")
}

export const bindApplication = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/Bind")
}

export const postBindingQuestion = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/BindingQuestions", payload)
}


export const getCoverages = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/Coverages")
}

export const updateCoverage = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/Coverages", payload)
}

export const getLocations = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/Locations")
}

export const deleteLocations = (applicationId, payload)=>{
    return sendDelete("Applications/"+applicationId+"/Locations", payload)
}

export const addLocation = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/Locations", payload)
}

export const reassignApplicationToUser = (applicationId, userId)=> {
    return sendPost("Applications/"+applicationId+"/Reassign/"+userId)
}

export const reassignMultipleApplicationsToUser = ( payload)=> {
    return sendPost("Applications/Reassign", payload)
}

export const uploadLocationsFile = (applicationId, file) => {
    return uploadFile("Applications/"+applicationId+"/Locations/Upload", file)
}

export const getAbovegroundStorageTanks = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/AbovegroundStorageTanks")
}

export const deleteAbovegroundStorageTanks = (applicationId, payload)=>{
    return sendDelete("Applications/"+applicationId+"/AbovegroundStorageTanks", payload)
}

export const addAbovegroundStorageTank = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/AbovegroundStorageTanks", payload)
}

export const uploadAbovegroundStorageTankFile = (applicationId, file) => {
    return uploadFile("Applications/"+applicationId+"/AbovegroundStorageTanks/Upload", file)
}

export const getUndergroundStorageTanks = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/UndergroundStorageTanks")
}

export const deleteUndergroundStorageTanks = (applicationId, payload)=>{
    return sendDelete("Applications/"+applicationId+"/UndergroundStorageTanks", payload)
}

export const addUndergroundStorageTank = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/UndergroundStorageTanks", payload)
}

export const uploadUndergroundStorageTankFile = (applicationId, file) => {
    return uploadFile("Applications/"+applicationId+"/UndergroundStorageTanks/Upload", file)
}

export const validateStorageTanks = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/StorageTanks/Validate")
}

export const getRequirementQuestions = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/RequirementQuestions")
}

export const getEndorsementQuestion1Setting = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/EndorsementQuestion1Setting")
}

export const updateRequirementQuestion = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/RequirementQuestions", payload)
}

export const updateEndorsementQuestion = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/EndorsementQuestions", payload)
}

export const associateInsuredWithApplication = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/AdditionalNamedInsureds", payload)
}

export const deleteAdditionalInsured = (applicationId, payload)=> {
    return sendDelete("Applications/"+applicationId+"/AdditionalNamedInsureds", payload)
}

export const getInsuranceFraudWarningStatements = (applicationId, payload)=> {
    return sendGet("Applications/"+applicationId+"/InsuranceFraudWarningStatements", payload)
}

export const signApplication = (applicationId, payload)=> {
    return sendPost("Applications/"+applicationId+"/Sign", payload)
}

export const validateEffectiveDate = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/EffectiveDate/Validate")
}

export const completeApplication = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/Complete")
}

export const cloneApplication = (applicationId)=> {
    return sendPost("Applications/"+applicationId+"/Clone")
}

export const viewApplication = (applicationId)=> {
    return sendGet("Applications/"+applicationId+"/View")
}