/** @jsxImportSource @emotion/react */
import  { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { css } from "@emotion/react/macro";
import DatePicker from "react-datepicker";

const componentStyle = css`
  max-width: 50%;
  .export-report {
    margin-top: 24px;
    align-self: flex-end;
  }

  .export-report:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }

  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
`;

class Reporting extends Component {
  state = {
    isLoading: false,
    type: "",
    fromDate: null,
    toDate: null,
    status: "",
    typeOptions: [],
    statusOptions: [],
    error: null,  
  };

  componentDidMount() {
    this.fetchReportingPageData();
  }

  componentDidUpdate() {
    if (this.state.error) {
      setTimeout(() => {
        this.setState({ error: null });
      }, 3000);
    }
  }

  fetchReportingPageData = async () => {
    const { reportingContainer } = this.props;
    await reportingContainer.getReportingTypes();
    await reportingContainer.getApplicationsStatus();
  };

  getReport = async () => {
    const { reportingContainer } = this.props;

   
    try {
      const payload = {
        type: this.state.type,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        status: this.state.status,
      };
      const result = await reportingContainer.exportReportingData(payload);
      const { mimeType, file, fileName, succeeded } = result;
      if(succeeded && file){
        const linkSource = `data:${mimeType};base64,${file}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
   
    } catch (error) {
      this.setState({
        error: "There was a problem exporting the data. Please try again.",
      });
    }
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const { reportingContainer } = this.props;
    const { type, fromDate, toDate, status } = this.state;
    const { reportingTypes, applicationStatus } = reportingContainer.state;

    return (
      <div css={componentStyle}>
        <p>Please note: <span className="required">*</span> indicates required field.</p>

        <Row md={12}>
          <Col md={12}>
            <Form.Label>
              Type<span className="required">*</span>
            </Form.Label>
            <Form.Select
              size="sm"
              name="type"
              className="form-control form-control-sm"
              value={type}
              onChange={(e) => this.handleChange("type", e.currentTarget.value)}
            >
              <option value="">--- Select Type ---</option>
              {reportingTypes?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row md={12} className="mt-2">
          <Col md={6}>
            <Form.Label>From Date</Form.Label>
            <div className="customDatePickerWidth">
              <DatePicker
                dateFormat="MM/dd/yyyy"
                popperPlacement="bottom"
                className="form-control form-control-sm"
                selected={fromDate}
                onChange={(date) => this.handleChange("fromDate", date)}
              />
            </div>
          </Col>
          <Col md={6}>
            <Form.Label>To Date</Form.Label>
            <div className="customDatePickerWidth">
              <DatePicker
                dateFormat="MM/dd/yyyy"
                popperPlacement="bottom"
                className="form-control form-control-sm"
                selected={toDate}
                onChange={(date) => this.handleChange("toDate", date)}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={12}>
            <Form.Label>Status</Form.Label>
            <Form.Select
              size="sm"
              name="status"
              className="form-control form-control-sm"
              value={status}
              onChange={(e) =>
                this.handleChange("status", e.currentTarget.value)
              }
            >
              <option value="">--- Select Status ---</option>
              {applicationStatus?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="d-flex justify-content-end">
            <Button
              className="export-report"
              size="sm"
              variant="primary"
              disabled={!type || reportingContainer.state.isLoading}
              onClick={this.getReport}
            >
              Export Report
            </Button>
          </Col>
        </Row>
        {this.state.error && (
          <div className="row mt-3">
            <div className="col">
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Reporting;
