import Main from "./scenes/Main"
import { Provider } from "unstated"
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
    return (
        <Provider>
            <Main />
        </Provider>   
    )
}

export default App
